.goods-list-wrap {
  width: 1200px;
  min-width: 1200px;
  margin: 10px auto;
  background: #fff;

  .ant-pagination {
    padding: 32px 0 8px;
    text-align: center;
  }

  .ant-spin {
    display: block;
    padding: 48px 0 36px;
  }

  .ant-empty {
    padding: 48px 0 36px;
  }
}

.goods-list-row {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid var(--border-deep);

  .check-box {
    width: 20px;
    margin-right: 10px;
    align-self: center;
    cursor: pointer;

    &:hover {

      color: var(--color);
    }

    .icon-check-s {
      color: var(--color);
    }
  }

  .image-box {
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer;

    .tips-box {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 32px;
      font-size: 14px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, .8);
    }
  }

  .detail-box {
    width: 348px;
    padding-left: 24px;
    word-break: break-all;
    cursor: pointer;
    position: relative;

    .name {
      font-size: 14px;
    }

    .tips {
      font-size: 12px;
      color: var(--font-tips);
    }

    .ant-select {
      margin-top: 12px;
    }
  }

  .describe-box {
    width: 336px;
    padding-left: 24px;

    .price {
      font-size: 16px;
      color: var(--font-high);
    }

    .unit {
      font-size: 12px;
      color: var(--font-tips);
    }
    .gold-price{
      color:#000 ;
      span:nth-of-type(2){
        font-size: 14px;
        margin-left: 12px;
        padding:0 3px 0 3px;
        color: #FFE0BA;
        background-color: #232120;
        border-radius: 5px;
      }
    }
    .row {
      color: var(--font-normal);
    }

    .icon-goods-tag-delivery,
    .icon-goods-tag-shipments {
      color: var(--font-high);
      margin-right: 4px;
    }
  }

  .cart-box {
    width: 246px;
    padding-left: 24px;

    .stock {
      font-size: 14px;
      color: var(--font-tips);
    }

    .label {
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
      padding: 0 8px;
      color: var(--font-title);
      border-radius: 12px;
      background: #F5F5F5;
    }
  }

  .operate-box {
    align-self: center;

    .btn {
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      border: 0 none;
      border-radius: 4px;
      background: var(--color);
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }

      &:disabled {
        background: var(--disabled);
      }
    }
  }
}

.goods-detail-head {
  display: flex;
  padding: 20px 16px;

  .goods-detail-box {
    flex: 1;
    padding-left: 32px;

    .name {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 600;
      color: var(--font-title);
    }

    .code {
      color: var(--font-tips);

      .item {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        word-break: break-word;

      }

      .icon-shuoming {
        margin-right: 2px;
      }
    }
  }

  .coupon-box {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 16px;
    border-bottom: 2px dashed #e3e3e3;
    background: rgba(245, 245, 245, 1);

    .label {
      padding-right: 8px;
    }

    .more-btn {
      cursor: pointer;
    }

    .icon-jump {
      font-size: 12px;
    }

    .coupon-list-box {
      flex: 1;
      overflow: hidden;
      height: 20px;
      padding: 1px 0;
    }

    .coupon-label {
      align-items: center;
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-hint);
      position: relative;
      margin: 0 4px 0 2px;
      padding: 0 8px;
      border-radius: 2px;
      background: radial-gradient(circle at left, transparent 4px, #FFEFF0 0) left / 60% no-repeat,
      radial-gradient(circle at right, transparent 4px, #FFEFF0 0) right / 60% no-repeat;
      filter: drop-shadow(0 0 1px var(--color-hint));
    }
  }
}

.goods-product-box {
  margin-top: 32px;
}

.goods-product-item {
  display: flex;
  margin-bottom: 32px;
  font-size: 14px;
  color: var(--font-tips);

  .detail-box {
    width: 340px;
    padding-right: 16px;

    .format {
      line-height: 24px;
      color: var(--font-normal);

      .code {
        color: var(--font-tips);
      }
    }

    .icon-spec-picture {
      color: var(--font-tips);
      margin-right: 4px;
      font-size: 14px;

      &:hover {
        opacity: .8;
        cursor: pointer;
      }
    }

    .iconimg {
      color: var(--font-title);
    }
  }

  .describe-box {
    margin-top: 8px;

    .row {
      padding-right: 24px;
    }

    .iconfont {
      color: var(--font-high);
    }
  }

  .price-box {
    width: 120px;

    .price {
      font-size: 16px;
      color: var(--font-high);

      .unit {
        font-size: 12px;
        color: var(--font-tips);
      }
    }
  }

  .uint-box {
    margin-top: 8px;
  }

  .cart-box {
    width: 126px;

    .stock {
      margin-top: 8px;
    }
  }

  .operate-box {
    align-self: flex-end;

    .btn {
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      border: 0 none;
      border-radius: 4px;
      background: var(--color);
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }

      &:disabled {
        background: var(--disabled);
      }
    }
  }
}

.goods-detail-introduce {
  width: 800px;
  margin: 24px auto 0;
  word-break: break-all;
  white-space: normal;
}

.goods-detail-property {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;

  .item {
    display: inline-block;
    width: 294px;
    padding-right: 120px;
    vertical-align: top;
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--font-normal);
  }
}

.goods-detail-tabs {
  .ant-tabs-nav-container {
    height: 54px;
    padding-top: 8px;
    padding-left: 16px;
    background: #F5F5F5;

    .ant-tabs-nav {
      .ant-tabs-tab {
        &:hover,
        &:active {
          color: var(--color);
        }
      }

      .ant-tabs-tab-active {
        color: var(--color);
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--color);
    }
  }

  .ant-pagination {
    margin: 62px auto 24px;
    text-align: center;
  }

  .ant-empty {
    margin: 48px 0 24px;
  }
}

.goods-activity-head {
  padding: 16px 0;
  margin-bottom: 16px;
  background-color: #fff;

  .goods-activity-wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
    .breadcrumb-block-main{
      padding: 0;
    }
  }

  .title {
    font-size: 16px;
    color: var(--color-hint);
  }

  .info {
    font-size: 12px;
    color: var(--font-tips);
  }
}
