
.floor-middle-box {
  max-width: 1200px;
  margin: 0 auto;
}

.floor-grail-layout {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .layout-little-box {
    width: 200px;
  }

  .layout-large-box {
    width: 990px;
  }
}
