.useful-expressions-box {
  width: 400px;

  dt, dd {
    padding: 10px 12px;
    border-bottom: 1px solid #e3e3e3;
    color: #333;
  }

  dd {
    color: #666;

    &:hover {
      cursor: pointer;
      color: var(--color);
    }
  }
}
