@charset "UTF-8";
/*定义公共部分通用css，默认所有页面都需要加载 2016/08/17*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figure,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

html {
  height: 100%;
  overflow: hidden; }

body {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background: #f5f5f5; }

input,
select,
textarea,
button {
  font-family: "Arial,Helvetica,Microsoft YaHei"; }

select,
input,
button {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none; }

h1,
h2,
h3,
h4,
h5,
h6,
i,
b {
  font-weight: normal; }

i {
  font-style: normal; }

ul,
ol,
li {
  list-style-type: none; }

img {
  border: 0;
  vertical-align: middle;
  max-width: 100%; }

img:not([src]) {
  opacity: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a:link,
a:visited {
  color: inherit;
  text-decoration: none; }

a:hover,
a:active {
  color: inherit;
  text-decoration: none; }

/* 取消苹果圆角 */
input,
button,
textarea {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  border-radius: 0; }

input {
  font-size: 14px; }

button {
  padding: 0; }

p {
  padding: 0;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0; }

input[disabled],
textarea[diabled] {
  color: #666;
  color: var(--font-normal);
  -webkit-text-fill-color: #666;
  -webkit-text-fill-color: var(--font-normal);
  opacity: 1; }

/* placeholder  */
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b3b3b3;
  color: var(--disabled); }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b3b3b3;
  color: var(--disabled); }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #b3b3b3;
  color: var(--disabled); }

input[disabled]::-webkit-input-placeholder,
textarea[diabled]::-webkit-input-placeholder {
  color: #b3b3b3;
  color: var(--disabled);
  -webkit-text-fill-color: #b3b3b3;
  -webkit-text-fill-color: var(--disabled); }

button,
input[type='button'] {
  cursor: pointer; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[readonly] {
  -webkit-user-select: none; }

.h {
  display: none;
  visibility: visible; }

.fix {
  *zoom: 1; }

.fix:after {
  clear: both;
  content: "";
  display: table; }

.fl {
  float: left; }

.fr {
  float: right; }

.pos-r {
  position: relative; }

.pos-a {
  position: absolute; }

.pos-f {
  position: fixed; }

.tc {
  text-align: center !important; }

.tl {
  text-align: left !important; }

.tr {
  text-align: right !important; }

.cur {
  cursor: pointer; }

.align-center {
  align-content: center;
  align-items: center;
  justify-content: center; }

.dis-show {
  display: block !important; }

.disShow {
  display: inline-block !important; }

.dis-flex {
  display: flex !important; }

.dis-hide {
  display: none !important; }

.dis-inb {
  display: inline-block !important; }

.dis-b {
  display: block; }

.va-t {
  vertical-align: top; }

.va-m {
  vertical-align: middle; }

.va-b {
  vertical-align: bottom; }

.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb8 {
  margin-bottom: 8px; }

.mt300 {
  margin-top: 300px !important; }

.p0 {
  padding: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.plr12 {
  padding: 0 12px; }

.pb0 {
  padding-bottom: 0 !important; }

.txt-hint {
  color: #FF3838;
  color: var(--color-hint); }

.txt-red {
  color: #ff3838 !important;
  color: var(--orangey-red) !important; }

.txt-green {
  color: #4db814 !important;
  color: var(--font-green) !important; }

.txt-tips {
  color: #999 !important;
  color: var(--font-tips) !important; }

.txt-title {
  color: #333 !important;
  color: var(--font-title) !important; }

.txt-main-color {
  color: #DC3848 !important;
  color: var(--color) !important; }

.txt-hover {
  cursor: pointer; }
  .txt-hover:hover {
    color: #DC3848;
    color: var(--color); }

.bg-white {
  background: #fff !important; }

.flex {
  display: flex; }

.flex-children {
  flex: 1 1; }

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ellipsis-2 {
  display: -webkit-flex;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: normal; }

.bl {
  border-left: 1px solid #DDDDDD;
  border-left: 1px solid var(--border-deep); }

.br {
  border-right: 1px solid #DDDDDD;
  border-right: 1px solid var(--border-deep); }

.bt {
  border-top: 1px solid #DDDDDD;
  border-top: 1px solid var(--border-deep); }

.bb {
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep); }

.b0 {
  border: 0 none; }

.width-auto {
  width: auto !important; }

.mask, .mask-son, .mask-pop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  background: rgba(26, 26, 26, 0.3); }

.mask {
  z-index: 3; }

.mask-son {
  z-index: 5; }

.mask-pop {
  z-index: 7; }

.page-wrap {
  max-width: 1200px;
  margin: 0 auto; }

.page-box-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

.page-body {
  flex: 1 1;
  width: 1200px;
  margin: 0 auto;
  display: flex; }
  .page-body .page-main {
    flex: 1 1;
    width: 0; }

.page-max {
  width: 100vw; }

.bg-white {
  background: #fff; }

.login-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 304px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .login-modal .login-modal-title {
    width: 304px;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    text-align: center;
    background: #fff;
    color: #333;
    color: var(--font-title);
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #DDDDDD;
    border-bottom: 1px solid var(--border-deep); }
  .login-modal .login-modal-body {
    padding: 32px 0 32px;
    text-align: center;
    background: #fff; }
    .login-modal .login-modal-body .btn {
      width: 240px;
      height: 50px;
      margin-bottom: 16px;
      border-radius: 50px;
      font-size: 18px;
      color: #666;
      color: var(--font-normal);
      border: 1px solid #DDDDDD;
      border: 1px solid var(--border-deep); }
    .login-modal .login-modal-body .btn-confirm {
      background: #DC3848;
      background: var(--color);
      color: #fff; }
      .login-modal .login-modal-body .btn-confirm:hover {
        background: rgba(231, 47, 62, 0.8);
        background: var(--color-active); }
  .login-modal .close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 22px;
    height: 22px;
    cursor: pointer; }
    .login-modal .close:after, .login-modal .close:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
              transform: translate(-50%, -50%) rotate(45deg);
      background: #999; }
    .login-modal .close:hover:after, .login-modal .close:hover:before {
      background: #666; }
    .login-modal .close:after {
      width: 1px;
      height: 14px; }
    .login-modal .close:before {
      width: 14px;
      height: 1px; }

.null-list-box {
  text-align: center;
  padding-top: 250px;
  padding-bottom: 1em; }
  .null-list-box img {
    width: 200px;
    height: 162px; }
  .null-list-box .null-tips {
    font-size: 14px;
    color: #999;
    color: var(--font-tips); }

.inquiry-model {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 300px;
  min-height: 200px;
  border-radius: 6px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  overflow: hidden;
  padding-bottom: 10px; }
  .inquiry-model .close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer; }
    .inquiry-model .close:hover {
      opacity: .8; }
    .inquiry-model .close:after, .inquiry-model .close:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
              transform: translate(-50%, -50%) rotate(45deg);
      background: #999;
      background: var(--font-tips); }
    .inquiry-model .close:after {
      width: 1px;
      height: 16px; }
    .inquiry-model .close:before {
      width: 16px;
      height: 1px; }
  .inquiry-model .inquiry-model-title {
    padding: 32px 24px;
    font-size: 16px;
    text-align: center;
    background: #fff;
    color: #333;
    color: var(--font-title);
    font-weight: bold;
    word-break: break-all; }
  .inquiry-model .btn {
    display: block;
    width: 200px;
    height: 40px;
    margin: 0 auto 16px;
    background: #fcfcfc;
    background: var(--border-light);
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    color: #333;
    color: var(--font-title);
    border-radius: 50px;
    cursor: pointer; }
    .inquiry-model .btn:hover {
      opacity: .8; }

.page-custom-modal .ant-modal-header {
  padding: 14px 24px;
  text-align: center;
  background: #E3E3E3; }

.page-custom-modal .ant-modal-title {
  font-size: 18px;
  color: #333;
  color: var(--font-title); }

.page-custom-modal .ant-modal-close-x {
  width: 48px;
  height: 48px; }

.model-height-450 .ant-modal-content .ant-modal-body {
  height: 450px;
  overflow: auto; }

.epc-detail-modal {
  width: 84vw !important; }
  .epc-detail-modal .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%; }
  .epc-detail-modal .ant-modal-content .ant-modal-body {
    padding: 0; }

.null-tips-box {
  text-align: center;
  padding-top: 100px; }
  .null-tips-box .null-icon {
    display: inline-block; }
    .null-tips-box .null-icon .svg-icon {
      width: 90px;
      height: 90px; }
  .null-tips-box .txt {
    font-size: 14px;
    color: #666;
    color: var(--font-normal); }

.user-card {
  display: flex;
  align-content: center;
  align-items: center; }
  .user-card .head-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px; }
    .user-card .head-img img, .user-card .head-img .svg-icon {
      width: 100%;
      height: 100%; }
  .user-card .main-box {
    flex: 1 1;
    font-size: 12px;
    line-height: 1.2; }
    .user-card .main-box .text-title {
      color: #333;
      color: var(--font-title); }
    .user-card .main-box .text-normal {
      margin-top: 4px;
      color: #666;
      color: var(--font-normal); }
    .user-card .main-box .text-tips {
      margin-top: 4px;
      color: #999;
      color: var(--font-tips); }

.statistic-box-card {
  display: flex; }
  .statistic-box-card.statistic-box-card-divider .statistic-item-card:first-child:before {
    display: none; }
  .statistic-box-card.statistic-box-card-divider .statistic-item-card:before {
    content: "";
    width: 1px;
    height: 24px;
    background: #dcdcdc;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .statistic-box-card .statistic-item-card {
    flex: 1 1;
    width: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    position: relative; }
    .statistic-box-card .statistic-item-card .statistic-title {
      font-size: 12px;
      color: #999;
      color: var(--font-tips);
      margin-top: 4px; }
    .statistic-box-card .statistic-item-card .statistic-main {
      font-size: 20px;
      color: #333;
      color: var(--font-title);
      font-weight: bold; }
    .statistic-box-card .statistic-item-card .txt-hint {
      color: #FF3838;
      color: var(--font-high); }

.cascader-box {
  width: 100%; }

.cont-table table {
  width: 100%;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  table-layout: fixed; }

.cont-table th {
  height: 40px;
  vertical-align: middle;
  background: #f5f5f5;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding-left: 14px; }

.cont-table tr:last-child {
  border-bottom: 0 none; }

.cont-table td {
  font-size: 14px;
  color: #666;
  color: var(--font-normal);
  padding-left: 14px;
  height: 40px;
  vertical-align: middle;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep); }
  .cont-table td .info-box {
    padding: 8px 0;
    font-size: 14px; }
    .cont-table td .info-box .title {
      color: #333;
      color: var(--font-title);
      margin-bottom: 4px; }
    .cont-table td .info-box .tips {
      color: #999;
      color: var(--font-tips); }
  .cont-table td .txt {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .cont-table td .ope {
    width: auto;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer; }
    .cont-table td .ope:hover {
      color: #DC3848;
      color: var(--color); }

.chat-btn-box {
  margin-right: 10px;
  vertical-align: middle;
  font-size: 12px;
  color: #DC3848;
  color: var(--color);
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  align-content: center;
  cursor: pointer; }
  .chat-btn-box:hover, .chat-btn-box:active {
    opacity: .8; }
  .chat-btn-box img {
    width: 20px;
    height: 20px;
    margin-right: 4px; }

:root {
  --color: #DC3848;
  --color-active: rgba(231, 47, 62, 0.8);
  --color-hover: rgba(231, 47, 62, .1);
  --color-hint: #FF3838;
  --border-deep: #DDDDDD;
  --border-middle: #EEEEEE;
  --border-light: #fcfcfc;
  --font-title: #333;
  --font-normal: #666;
  --font-tips: #999;
  --font-high: #FF3838;
  --disabled: #b3b3b3;
  --bg-color: #fDDDDDD;
  --btn-color: #ffb919;
  --orangey-red: #ff3838;
  --orange: #ffb67a;
  --orange-hover: #cc9e38;
  --orange-red: rgba(255, 99, 80, 0.9);
  --font-green: #4db814; }

.theme-dc3848 {
  --color: #DC3848;
  --color-active: rgba(231, 47, 62, 0.8);
  --color-hover: rgba(231, 47, 62, .1); }

.theme-eb382f {
  --color: #eb382f;
  --color-active: #d1322a;
  --color-hover: rgba(235, 56, 47, .1); }

.theme-fb6321 {
  --color: #fb6321;
  --color-active: #fc824d;
  --color-hover: rgba(251, 99, 33, .1); }

.theme-b25724 {
  --color: #b25724;
  --color-active: #994b1f;
  --color-hover: rgba(178, 87, 36, .1); }

.theme-dbaf58 {
  --color: #dbaf58;
  --color-active: #c29b4e;
  --color-hover: rgba(219, 175, 88, .1); }

.theme-fab414 {
  --color: #fab414;
  --color-active: #e0a212;
  --color-hover: rgba(250, 180, 20, .1); }

.theme-23982d {
  --color: #23982d;
  --color-active: #4fac57;
  --color-hover: rgba(35, 152, 45, .1); }

.theme-f566d3 {
  --color: #f566d3;
  --color-active: #db5cbe;
  --color-hover: rgba(245, 102, 211, .1); }

.theme-c38cfa {
  --color: #c38cfa;
  --color-active: #b07ee0;
  --color-hover: rgba(195, 140, 250, .1); }

.theme-1c69e6 {
  --color: #1c69e6;
  --color-active: #185dcc;
  --color-hover: rgba(28, 105, 230, .1); }

.theme-20ade5 {
  --color: #20ade5;
  --color-active: #4dbdea;
  --color-hover: rgba(32, 173, 229, .1); }

.theme-0ec2b9 {
  --color: #0ec2b9;
  --color-active: #10E1D7;
  --color-hover: rgba(14, 194, 185, .1); }

.theme-282828 {
  --color: #282828;
  --color-active: #494949;
  --color-hover: rgba(40, 40, 40, .1); }

.theme-2d6285 {
  --color: #2d6285;
  --color-active: #285473;
  --color-hover: rgba(45, 98, 133, .1); }

.page-reset p {
  margin: 0; }

.page-reset .ant-spin {
  color: #DC3848;
  color: var(--color); }

.page-reset .ant-spin-dot-item {
  background-color: #DC3848;
  background-color: var(--color); }

.page-reset .ant-pagination-item:hover, .page-reset .ant-pagination-item:focus {
  border-color: #DC3848;
  border-color: var(--color); }
  .page-reset .ant-pagination-item:hover a, .page-reset .ant-pagination-item:focus a {
    color: #DC3848;
    color: var(--color); }

.page-reset .ant-pagination-item-active {
  border-color: #DC3848;
  border-color: var(--color); }
  .page-reset .ant-pagination-item-active a {
    color: #DC3848;
    color: var(--color); }

.page-reset .ant-pagination-prev:focus a, .page-reset .ant-pagination-prev:hover a,
.page-reset .ant-pagination-next:focus a,
.page-reset .ant-pagination-next:hover a {
  color: #DC3848;
  color: var(--color); }

.page-reset .ant-pagination-prev:focus .ant-pagination-item-link, .page-reset .ant-pagination-prev:hover .ant-pagination-item-link,
.page-reset .ant-pagination-next:focus .ant-pagination-item-link,
.page-reset .ant-pagination-next:hover .ant-pagination-item-link {
  color: #DC3848;
  color: var(--color);
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-select-selection:focus, .page-reset .ant-select-selection:hover {
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-input:hover {
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-input:focus {
  border-color: #DC3848;
  border-color: var(--color);
  box-shadow: 0 0 0 2px rgba(231, 47, 62, .1);
  box-shadow: 0 0 0 2px var(--color-hover); }

.page-reset .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-btn-primary {
  background-color: #DC3848;
  background-color: var(--color);
  border-color: #DC3848;
  border-color: var(--color);
  color: #fff; }
  .page-reset .ant-btn-primary:hover, .page-reset .ant-btn-primary:focus {
    background-color: rgba(231, 47, 62, 0.8);
    background-color: var(--color-active);
    border-color: rgba(231, 47, 62, 0.8);
    border-color: var(--color-active); }

.page-reset .ant-btn-background-ghost.ant-btn-primary {
  color: #DC3848;
  color: var(--color);
  background-color: #DC3848;
  background-color: var(--color);
  border-color: #DC3848;
  border-color: var(--color); }
  .page-reset .ant-btn-background-ghost.ant-btn-primary:hover, .page-reset .ant-btn-background-ghost.ant-btn-primary:focus {
    color: rgba(231, 47, 62, 0.8);
    color: var(--color-active);
    background-color: rgba(231, 47, 62, 0.8);
    background-color: var(--color-active);
    border-color: rgba(231, 47, 62, 0.8);
    border-color: var(--color-active); }

.page-reset .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(231, 47, 62, .1);
  background-color: var(--color-hover); }

.page-reset .ant-select-open .ant-select-selection {
  border-color: #DC3848;
  border-color: var(--color);
  box-shadow: 0 0 0 2px rgba(231, 47, 62, .1);
  box-shadow: 0 0 0 2px var(--color-hover); }

.page-reset .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(231, 47, 62, .1);
  background-color: var(--color-hover); }

.page-reset .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background-color: #DC3848;
  background-color: var(--color); }

.page-reset .ant-calendar-date:hover {
  background: rgba(231, 47, 62, .1);
  background: var(--color-hover); }

.page-reset .ant-calendar-range .ant-calendar-in-range-cell::before {
  background-color: rgba(231, 47, 62, .1);
  background-color: var(--color-hover); }

.page-reset .ant-calendar-today .ant-calendar-date {
  color: #DC3848;
  color: var(--color);
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #DC3848;
  background: var(--color); }

.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: #DC3848;
  background: var(--color); }

.page-reset .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #DC3848;
  background: var(--color); }

.page-reset .ant-calendar-header a:hover {
  color: #DC3848;
  color: var(--color); }

.page-reset .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #DC3848;
  background: var(--color); }

.page-reset .ant-calendar-month-panel-month:hover {
  background: rgba(231, 47, 62, .1);
  background: var(--color-hover); }

.page-reset .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #DC3848;
  background-color: var(--color);
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-checkbox-checked::after {
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-checkbox-wrapper:hover .ant-checkbox-inner, .page-reset .ant-checkbox:hover .ant-checkbox-inner, .page-reset .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-typography a {
  color: inherit; }

.page-reset .ant-typography a:focus, .page-reset .ant-typography a:hover {
  color: #DC3848;
  color: var(--color); }

.page-reset .ant-radio-checked .ant-radio-inner {
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-radio-checked::after {
  border: 1px solid #DC3848;
  border: 1px solid var(--color); }

.page-reset .ant-radio-wrapper:hover .ant-radio, .page-reset .ant-radio:hover .ant-radio-inner, .page-reset .ant-radio-input:focus + .ant-radio-inner {
  border-color: rgba(231, 47, 62, 0.8);
  border-color: var(--color-active); }

.page-reset .ant-radio-inner::after {
  background-color: #DC3848;
  background-color: var(--color); }

.page-reset textarea.ant-input {
  resize: none; }

.page-reset .ant-radio-button-wrapper:hover {
  color: rgba(231, 47, 62, 0.8);
  color: var(--color-active); }

.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #DC3848;
  border-color: var(--color); }

.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #DC3848;
  color: var(--color);
  border-color: #DC3848;
  border-color: var(--color);
  box-shadow: -1px 0 0 0 #DC3848;
  box-shadow: -1px 0 0 0 var(--color); }

.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: rgba(231, 47, 62, 0.8);
  color: var(--color-active);
  border-color: rgba(231, 47, 62, 0.8);
  border-color: var(--color-active);
  box-shadow: -1px 0 0 0 rgba(231, 47, 62, 0.8);
  box-shadow: -1px 0 0 0 var(--color-active); }

.page-reset .ant-input-affix-wrapper:hover {
  border-color: #DC3848;
  border-color: var(--color);
  border-right-width: 1px !important; }

.page-reset .ant-modal-title {
  text-align: center; }

.page-reset .confirm-btn {
  width: 120px;
  height: 42px;
  text-align: center; }

.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #DC3848;
  background-color: var(--color); }

.page-reset .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #DC3848;
  background-color: var(--color); }

.page-reset .ant-pagination-next:focus .ant-pagination-item-link {
  background-color: #fff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65); }

.page-reset .ant-tabs-nav .ant-tabs-tab:hover {
  color: #DC3848;
  color: var(--color); }

.page-reset .ant-tabs-nav .ant-tabs-tab-active {
  color: #DC3848;
  color: var(--color); }

.page-reset .ant-tabs-ink-bar {
  background: #DC3848;
  background: var(--color); }

.page-reset .ant-carousel .slick-dots {
  z-index: 2; }

.floor-middle-box {
  max-width: 1200px;
  margin: 0 auto; }

.floor-grail-layout {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  .floor-grail-layout .layout-little-box {
    width: 200px; }
  .floor-grail-layout .layout-large-box {
    width: 990px; }

.inquire-page-box {
  height: 100%;
  background: #fff;
  padding: 16px 16px 80px;
  position: relative; }
  .inquire-page-box .ant-spin-nested-loading > div > .ant-spin {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.spin-box {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; }

.inquire-head-wrap {
  display: flex;
  justify-content: space-between; }
  .inquire-head-wrap .search-wrap {
    width: 578px;
    border: 1px solid #DC3848;
    border: 1px solid var(--color);
    position: relative; }
    .inquire-head-wrap .search-wrap .search-box .txt {
      padding-right: 120px;
      border: 0 none; }
    .inquire-head-wrap .search-wrap .search-box .ant-input-suffix {
      padding: 0 4px;
      font-size: 24px;
      text-align: center;
      line-height: 32px; }
      .inquire-head-wrap .search-wrap .search-box .ant-input-suffix .ant-input-clear-icon {
        font-size: 16px; }
    .inquire-head-wrap .search-wrap .search-box .ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 2px #DC3848;
      box-shadow: 0 0 0 2px var(--color); }
    .inquire-head-wrap .search-wrap .ant-input {
      border: 0 none;
      height: 38px; }
    .inquire-head-wrap .search-wrap .search-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 120px;
      background: #DC3848;
      background: var(--color);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      border: 0 none;
      z-index: 1; }
      .inquire-head-wrap .search-wrap .search-btn .file-upload:hover {
        cursor: pointer; }
      .inquire-head-wrap .search-wrap .search-btn:hover {
        opacity: .9; }
      .inquire-head-wrap .search-wrap .search-btn.search-btn-disabled {
        background: #b3b3b3;
        background: var(--disabled); }
      .inquire-head-wrap .search-wrap .search-btn i {
        font-size: 18px;
        margin-right: 4px; }
    .inquire-head-wrap .search-wrap .search-dropdown-box {
      position: absolute;
      top: 49px;
      left: 0;
      right: 0;
      height: 344px;
      background: #fff;
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #f0f0f0;
      z-index: 1; }
      .inquire-head-wrap .search-wrap .search-dropdown-box .search-dropdown-wrap {
        padding: 16px; }
      .inquire-head-wrap .search-wrap .search-dropdown-box .log-item {
        font-size: 12px;
        cursor: pointer;
        padding: 12px 0;
        border-bottom: 1px solid #DDDDDD;
        border-bottom: 1px solid var(--border-deep); }
        .inquire-head-wrap .search-wrap .search-dropdown-box .log-item:first-child {
          padding-top: 0; }
        .inquire-head-wrap .search-wrap .search-dropdown-box .log-item .tit {
          color: #333;
          color: var(--font-title); }
        .inquire-head-wrap .search-wrap .search-dropdown-box .log-item .txt {
          color: #999;
          color: var(--font-tips); }
        .inquire-head-wrap .search-wrap .search-dropdown-box .log-item .del:hover {
          color: #DC3848;
          color: var(--color); }

.inquire-null-tips-box {
  width: 100%;
  text-align: center;
  padding-top: 80px; }

.car-info-box {
  display: flex;
  padding: 16px;
  background: #FAFAFA;
  margin-top: 16px;
  position: relative; }
  .car-info-box:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #fafafa;
    position: absolute;
    top: -8px;
    left: 40px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .car-info-box .car-img {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: #fff;
    text-align: center;
    margin-right: 16px; }
    .car-info-box .car-img img {
      max-width: 100%;
      max-height: 100%; }
  .car-info-box .car-info-wrap {
    flex: 1 1; }
    .car-info-box .car-info-wrap .title {
      font-size: 16px;
      color: #333;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1;
      margin-bottom: 8px; }
    .car-info-box .car-info-wrap .car-list {
      margin-bottom: 8px; }
      .car-info-box .car-info-wrap .car-list .car-item {
        display: inline-block;
        vertical-align: middle;
        min-width: 160px;
        padding: 0 1em;
        height: 24px;
        margin-right: 12px;
        text-align: center;
        line-height: 24px;
        border-radius: 2px;
        font-size: 12px;
        border: 1px solid #DDDDDD;
        border: 1px solid var(--border-deep);
        color: #333;
        color: var(--font-title);
        cursor: pointer; }
        .car-info-box .car-info-wrap .car-list .car-item:hover, .car-info-box .car-info-wrap .car-list .car-item.active {
          border-color: #DC3848;
          border-color: var(--color);
          color: #DC3848;
          color: var(--color); }

.inquire-body-wrap {
  min-height: 600px; }

.parts-box-wrap {
  margin-top: 32px; }
  .parts-box-wrap .title {
    padding-bottom: 6px;
    border-bottom: 1px solid #DDDDDD;
    border-bottom: 1px solid var(--border-deep);
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    color: var(--font-title);
    display: flex;
    align-items: center; }
    .parts-box-wrap .title:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 2px;
      height: 14px;
      background: #333;
      background: var(--font-title);
      margin-right: 6px; }
  .parts-box-wrap .parts-box-main {
    padding-top: 16px; }
    .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap {
      display: inline-block;
      vertical-align: middle;
      width: 342px;
      position: relative; }
      .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area {
        display: flex; }
        .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .txt {
          flex: 1 1;
          height: 42px;
          border: 1px solid #DDDDDD;
          border: 1px solid var(--border-deep);
          border-radius: 4px 0 0 4px;
          padding-left: 1em;
          font-size: 14px;
          color: #333;
          color: var(--font-title); }
        .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .search-btn {
          display: inline-block;
          width: 52px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          background: #DC3848;
          background: var(--color);
          color: #fff;
          font-size: 20px;
          cursor: pointer; }
          .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .search-btn:hover {
            opacity: .8; }
      .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap {
        position: absolute;
        top: 32px;
        left: 0;
        right: 0;
        height: 343px;
        background: #fff;
        box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #f0f0f0;
        padding: 0 14px; }
        .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap .spin-box {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap li {
          font-size: 14px;
          color: #333;
          color: var(--font-title);
          padding: 5px 0;
          line-height: 22px;
          border-bottom: 1px solid #DDDDDD;
          border-bottom: 1px solid var(--border-deep);
          cursor: pointer; }
          .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap li:hover {
            color: #DC3848;
            color: var(--color); }
    .parts-box-wrap .parts-box-main .search-box-wrap .epc-btn {
      margin-left: 16px;
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: #DC3848;
      color: var(--color);
      cursor: pointer; }
      .parts-box-wrap .parts-box-main .search-box-wrap .epc-btn .iconfont {
        font-size: 18px; }
    .parts-box-wrap .parts-box-main .search-box-wrap .user-info {
      margin-right: 20px;
      display: inline-flex;
      align-content: center;
      align-items: center;
      font-size: 14px;
      color: #333;
      color: var(--font-title);
      cursor: pointer; }
      .parts-box-wrap .parts-box-main .search-box-wrap .user-info .txt {
        margin-left: 6px; }
      .parts-box-wrap .parts-box-main .search-box-wrap .user-info .btn {
        margin-left: 6px;
        color: #DC3848;
        color: var(--color); }

.parts-result-box {
  margin-top: 24px; }
  .parts-result-box table {
    width: 100%;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    table-layout: fixed;
    margin-bottom: 16px; }
  .parts-result-box caption {
    caption-side: top;
    background: #f5f5f5;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    border-bottom: 0 none;
    padding: 12px 16px;
    color: #666;
    color: var(--font-normal); }
  .parts-result-box th {
    height: 40px;
    line-height: 40px;
    background: #f5f5f5;
    font-weight: normal;
    padding: 0 16px;
    border-bottom: 1px solid #DDDDDD;
    border-bottom: 1px solid var(--border-deep);
    color: #666;
    color: var(--font-normal); }
    .parts-result-box th:nth-child(2), .parts-result-box th:nth-child(3) {
      width: 15%; }
    .parts-result-box th .price {
      color: #FF3838;
      color: var(--font-high); }
      .parts-result-box th .price:hover {
        color: #FF3838;
        color: var(--font-high); }
    .parts-result-box th .epc-btn {
      cursor: pointer; }
      .parts-result-box th .epc-btn .svg-icon {
        width: 30px;
        height: 30px; }
    .parts-result-box th span {
      display: inline-block;
      margin-left: 10px;
      cursor: pointer; }
      .parts-result-box th span:hover {
        color: #DC3848;
        color: var(--color); }
      .parts-result-box th span .iconfont {
        font-size: 12px; }
    .parts-result-box th:last-child {
      text-align: right; }
  .parts-result-box td {
    padding: 12px 16px;
    border-bottom: 1px solid #DDDDDD;
    border-bottom: 1px solid var(--border-deep);
    color: #FF3838;
    color: var(--font-high); }
    .parts-result-box td p {
      margin-bottom: 0; }
    .parts-result-box td .tips {
      font-size: 14px;
      color: #999;
      color: var(--font-tips); }
    .parts-result-box td .info-box .tit {
      font-size: 14px;
      color: #333;
      color: var(--font-title);
      margin-bottom: 1em; }
    .parts-result-box td .info-box .tips {
      font-size: 14px;
      color: #999;
      color: var(--font-tips); }
      .parts-result-box td .info-box .tips span {
        display: inline-block;
        vertical-align: top;
        margin-right: 1em; }
        .parts-result-box td .info-box .tips span i {
          color: #FF3838;
          color: var(--font-high); }
    .parts-result-box td .price {
      display: inline-block;
      margin-right: 8px;
      font-size: 16px; }
    .parts-result-box td .cost {
      font-size: 12px;
      color: #999;
      color: var(--font-tips);
      text-decoration: line-through; }
    .parts-result-box td .cart {
      cursor: pointer; }
    .parts-result-box td:last-child {
      text-align: right; }
  .parts-result-box .remark {
    padding: 4px 0;
    color: #FF3838;
    color: var(--font-high); }
    .parts-result-box .remark .tag {
      border: 1px solid #33cccc;
      background-color: rgba(51, 204, 204, 0.2);
      color: #33cccc;
      padding: 0 8px;
      border-radius: 2px;
      margin-right: 8px; }

.ys-drawer-box {
  height: 84vh; }
  .ys-drawer-box .ant-drawer-content-wrapper:before {
    content: "·原厂数据·";
    display: inline-block;
    width: 144px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    font-size: 18px;
    background: #fff;
    border-radius: 50px 0 0 50px;
    position: absolute;
    top: 0;
    left: -144px; }
  .ys-drawer-box .ant-drawer-content-wrapper .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    overflow: hidden; }
    .ys-drawer-box .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body {
      flex: 1 1;
      height: 0;
      padding: 0; }
  .ys-drawer-box .epc-data-box {
    height: 100%; }
    .ys-drawer-box .epc-data-box .ant-layout {
      height: 100%;
      background: #fff;
      overflow: hidden;
      padding: 8px; }
      .ys-drawer-box .epc-data-box .ant-layout .ant-layout {
        padding: 0; }
    .ys-drawer-box .epc-data-box .ant-layout-sider {
      background: #f8f8f8;
      overflow: hidden;
      width: 160px; }
      .ys-drawer-box .epc-data-box .ant-layout-sider .label-box {
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        font-size: 12px;
        color: #333;
        color: var(--font-title);
        cursor: pointer; }
        .ys-drawer-box .epc-data-box .ant-layout-sider .label-box:hover, .ys-drawer-box .epc-data-box .ant-layout-sider .label-box.active {
          background: #DC3848;
          background: var(--color);
          color: #fff; }
    .ys-drawer-box .epc-data-box .sider-body-box {
      height: 100%; }
    .ys-drawer-box .epc-data-box .ant-spin-nested-loading {
      height: 100%; }
      .ys-drawer-box .epc-data-box .ant-spin-nested-loading .ant-spin-container {
        height: 100%; }
  .ys-drawer-box .structure-chart-box {
    padding: 16px 0 16px 16px;
    height: 100%; }
  .ys-drawer-box .structure-chart-card {
    display: inline-block;
    vertical-align: top;
    width: 198px;
    margin-right: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px 0 rgba(153, 153, 153, 0.2);
    background: #fff;
    cursor: pointer; }
    .ys-drawer-box .structure-chart-card:nth-child(3n) {
      margin-right: 0; }
    .ys-drawer-box .structure-chart-card .img-box {
      width: 100%;
      height: 198px;
      text-align: center;
      line-height: 198px; }
      .ys-drawer-box .structure-chart-card .img-box img {
        max-height: 100%;
        max-width: 100%; }
    .ys-drawer-box .structure-chart-card .title {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      color: #333;
      color: var(--font-title);
      text-align: center; }
  .ys-drawer-box .structure-chart-detail-box {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head {
      padding: 8px; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head .ant-breadcrumb span:first-child .ant-breadcrumb-link {
        cursor: pointer; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head .ant-breadcrumb span:first-child .ant-breadcrumb-link:hover {
          color: #DC3848;
          color: var(--color); }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body {
      display: flex;
      flex: 1 1;
      height: 0; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box {
        width: 100%;
        text-align: center;
        position: relative;
        flex: 1 1; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box {
          display: inline-block;
          width: 100%;
          text-align: center; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box img {
            width: 100%;
            height: 100%; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .part-img-box {
          position: relative; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .part-img-box span {
            border: 1px solid #dcdcdc;
            transition: all 0.3s ease-in-out; }
            .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .part-img-box span.active {
              border: 2px solid #0070d2;
              box-shadow: 0 0 4px 0 #0070d2;
              -webkit-transform: scale(1.2);
                      transform: scale(1.2);
              transition: all 0.3s ease-in-out; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .part-img-box span:hover {
            cursor: pointer; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box {
          font-size: 12px;
          color: #666;
          color: var(--font-normal);
          position: absolute;
          left: 50%;
          bottom: 0;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%); }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box .txt {
            display: inline-block;
            vertical-align: middle;
            margin: 0 8px; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box button {
            display: inline-block;
            vertical-align: middle;
            border: 0 none;
            width: auto;
            background: transparent;
            cursor: pointer; }
            .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box button:hover {
              color: #DC3848;
              color: var(--color); }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-title {
        font-size: 14px;
        color: #333;
        color: var(--font-title);
        line-height: 1em;
        margin: 16px 0 8px; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-title:before {
          content: "";
          display: inline-block;
          margin: 0 4px 0 14px;
          width: 2px;
          height: 14px;
          background: #333;
          background: var(--font-title); }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content {
        flex: 1 1;
        height: 0;
        position: relative; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .table-head {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          width: 100%;
          height: 40px;
          background: #fcfcfc;
          line-height: 40px;
          font-size: 14px;
          color: #666;
          color: var(--font-normal);
          border-bottom: 1px solid #DDDDDD;
          border-bottom: 1px solid var(--border-deep);
          z-index: 3; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .table-head .head-item {
            padding-left: 24px;
            width: calc(100% / 6); }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box .icon-btn-s {
          color: #DC3848 !important;
          color: var(--color) !important; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table {
          width: 100%;
          table-layout: fixed; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table th {
            height: 40px;
            background: #fcfcfc;
            opacity: 0;
            width: calc(100% / 6); }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table tr:hover {
            cursor: pointer; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td {
            height: 50px;
            padding-left: 24px;
            font-size: 14px;
            color: #333;
            color: var(--font-title);
            vertical-align: middle;
            border-bottom: 1px solid #DDDDDD;
            border-bottom: 1px solid var(--border-deep); }
            .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .txt {
              display: inline-block;
              width: 100%; }
            .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .btn {
              display: inline-block;
              width: 76px;
              height: 22px;
              border: 1px solid #DC3848;
              border: 1px solid var(--color);
              background: #fff;
              text-align: center;
              line-height: 20px;
              font-size: 12px;
              color: #DC3848;
              color: var(--color);
              cursor: pointer; }
              .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .btn:hover {
                opacity: .8; }
            .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn {
              display: inline-block;
              width: 78px;
              height: 24px;
              background: #DC3848;
              background: var(--color);
              text-align: center;
              line-height: 24px;
              font-size: 12px;
              color: #fff;
              cursor: pointer; }
              .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn:before {
                content: "已加入"; }
              .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn:hover:before {
                content: "取消加入"; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box .disable {
          background-color: #fffcde; }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer {
      height: 50px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
      position: relative;
      padding-left: 24px;
      line-height: 50px; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .tips-box {
        display: inline-block;
        font-size: 14px;
        color: #333;
        color: var(--font-title); }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .btn {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0 30px;
        background: #DC3848;
        background: var(--color);
        font-size: 16px;
        color: #fff;
        border: 0 none; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .btn:hover {
          opacity: .8; }

.epc-detail-box {
  width: 100%; }
  .epc-detail-box .img-box-wrap {
    width: 100%;
    text-align: center; }
    .epc-detail-box .img-box-wrap .img-box {
      display: inline-block;
      width: 400px;
      height: 400px;
      text-align: center;
      line-height: 400px;
      overflow: hidden; }
      .epc-detail-box .img-box-wrap .img-box img {
        max-width: 100%;
        max-height: 100%; }
  .epc-detail-box .info-box {
    margin-top: 10px; }
    .epc-detail-box .info-box .descriptions-box {
      border: 1px solid #DDDDDD;
      border: 1px solid var(--border-deep); }
      .epc-detail-box .info-box .descriptions-box .descriptions-item-box {
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid #DDDDDD;
        border-bottom: 1px solid var(--border-deep);
        margin: 0;
        padding: 0; }
        .epc-detail-box .info-box .descriptions-box .descriptions-item-box:last-child {
          border-bottom: 0 none; }
        .epc-detail-box .info-box .descriptions-box .descriptions-item-box .descriptions-title {
          width: 90px;
          border-right: 1px solid #DDDDDD;
          border-right: 1px solid var(--border-deep);
          margin: 0;
          padding-left: 1em; }
        .epc-detail-box .info-box .descriptions-box .descriptions-item-box .descriptions-main {
          padding-left: 1em; }

.inquire-foot-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid #f2f2f2;
  background: #fcfcfc; }
  .inquire-foot-wrap .btn-group {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }
    .inquire-foot-wrap .btn-group .btn {
      border: 0 none;
      width: 150px;
      height: 100%;
      line-height: 60px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      background: #DC3848;
      background: var(--color); }
      .inquire-foot-wrap .btn-group .btn[disabled] {
        background: #b3b3b3;
        background: var(--disabled);
        color: #fff; }
      .inquire-foot-wrap .btn-group .btn.minor {
        color: #DC3848;
        color: var(--color);
        background: rgba(231, 47, 62, .1);
        background: var(--color-hover); }
      .inquire-foot-wrap .btn-group .btn:hover {
        opacity: .8; }

.inquiry-list-page-box {
  height: 100%;
  background: #fff;
  margin-top: 14px; }

.inquiry-list-page-main {
  padding: 16px; }
  .inquiry-list-page-main .inquiry-list-page-main-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 8px; }
    .inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab {
      display: inline-block;
      vertical-align: middle;
      margin: 0 14px;
      font-size: 14px;
      color: #666;
      color: var(--font-normal);
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;
      cursor: pointer; }
      .inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab:hover {
        color: #DC3848;
        color: var(--color); }
      .inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab.active {
        color: #DC3848;
        color: var(--color);
        border-color: #DC3848;
        border-color: var(--color); }
    .inquiry-list-page-main .inquiry-list-page-main-head .search-box .input-wrap, .inquiry-list-page-main .inquiry-list-page-main-head .search-box .select-box {
      display: inline-block;
      vertical-align: middle; }
    .inquiry-list-page-main .inquiry-list-page-main-head .search-box .input-wrap {
      margin-right: 8px; }
  .inquiry-list-page-main .inquiry-list-page-main-body {
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep); }
    .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card {
      padding: 16px;
      border-bottom: 1px solid #DDDDDD;
      border-bottom: 1px solid var(--border-deep);
      font-size: 14px;
      display: flex;
      cursor: pointer; }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card:hover {
        background: rgba(231, 47, 62, .1);
        background: var(--color-hover); }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card:last-child {
        border-bottom: 0 none; }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .img-box {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px; }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .img-box img {
          max-width: 100%;
          max-height: 100%; }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box {
        flex: 1 1;
        width: 0;
        padding: 0 16px;
        color: #999;
        color: var(--font-tips); }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title {
          margin-bottom: 8px; }
          .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt-title {
            color: #333;
            color: var(--font-title); }
            .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt-title .tips {
              margin-left: 1em;
              color: #999;
              color: var(--font-tips); }
          .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt {
            margin-left: 1em;
            color: #666;
            color: var(--font-normal); }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .tips-box .txt {
        margin-left: 1em;
        color: #999;
        color: var(--font-tips); }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box {
        margin-top: 8px;
        border-top: 1px solid #DDDDDD;
        border-top: 1px solid var(--border-deep);
        padding-top: 8px; }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .tit {
          font-size: 14px;
          color: #999;
          color: var(--font-tips);
          margin-bottom: 4px; }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .remark-box {
          font-size: 14px;
          color: #333;
          color: var(--font-title);
          line-height: 1.5;
          margin-bottom: 4px;
          word-break: break-all;
          white-space: normal; }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .attachment-main .img-box {
          display: inline-block;
          vertical-align: top;
          border: 1px solid #DDDDDD;
          border: 1px solid var(--border-deep);
          width: 80px;
          height: 80px;
          text-align: center;
          line-height: 80px;
          margin-right: 8px; }
          .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .attachment-main .img-box img {
            max-width: 100%;
            max-height: 100%; }
  .inquiry-list-page-main .inquiry-list-page-main-foot {
    margin-top: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center; }

.inquiry-detail-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%; }

.inquiry-detail-page-main {
  padding: 16px; }
  .inquiry-detail-page-main .car-info-box {
    align-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    margin-top: 0;
    margin-bottom: 16px;
    padding: 8px 16px; }
    .inquiry-detail-page-main .car-info-box:before {
      display: none; }
    .inquiry-detail-page-main .car-info-box .img-box {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      background: #fff; }
      .inquiry-detail-page-main .car-info-box .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .inquiry-detail-page-main .car-info-box .info-box {
      flex: 1 1;
      padding-left: 24px;
      color: #999;
      color: var(--font-tips); }
      .inquiry-detail-page-main .car-info-box .info-box span {
        margin-right: 16px; }
    .inquiry-detail-page-main .car-info-box .text-btn {
      color: #333;
      color: var(--font-title);
      cursor: pointer; }
      .inquiry-detail-page-main .car-info-box .text-btn:hover {
        color: #DC3848;
        color: var(--color); }
  .inquiry-detail-page-main .inquiry-create-page-main-box {
    flex-direction: row-reverse !important; }
  .inquiry-detail-page-main .detail-main-box {
    display: flex;
    flex-direction: row; }
    .inquiry-detail-page-main .detail-main-box .left-box {
      width: 256px;
      height: 560px;
      margin-right: 16px;
      background: #f8f8f8;
      border: 1px solid #DDDDDD;
      border: 1px solid var(--border-deep); }
      .inquiry-detail-page-main .detail-main-box .left-box .title {
        padding: 12px 0 12px 24px; }
      .inquiry-detail-page-main .detail-main-box .left-box .part-box:hover, .inquiry-detail-page-main .detail-main-box .left-box .part-box.active {
        color: #DC3848;
        color: var(--color);
        background: #fff; }
      .inquiry-detail-page-main .detail-main-box .left-box li {
        width: 100%;
        white-space: normal;
        word-break: break-all;
        font-size: 14px;
        padding: 12px 16px 2px 16px;
        color: #333;
        color: var(--font-title);
        cursor: pointer; }
        .inquiry-detail-page-main .detail-main-box .left-box li .tips {
          font-size: 12px;
          color: #999;
          color: var(--font-tips); }
        .inquiry-detail-page-main .detail-main-box .left-box li:hover, .inquiry-detail-page-main .detail-main-box .left-box li.active {
          color: #DC3848;
          color: var(--color);
          background: #fff;
          font-weight: bold; }
      .inquiry-detail-page-main .detail-main-box .left-box .sider-body-box .new-label-box {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 6px 12px;
        font-size: 12px;
        color: #333;
        text-align: center;
        border: 0px solid #e3e3e3;
        border-radius: 3px;
        background-color: #fff;
        margin: 1px 24px 0;
        justify-content: space-between; }
        .inquiry-detail-page-main .detail-main-box .left-box .sider-body-box .new-label-box .oe-name {
          font-size: 14px;
          font-weight: bold;
          width: 300px; }
        .inquiry-detail-page-main .detail-main-box .left-box .sider-body-box .new-label-box .epc-box {
          display: flex;
          flex-direction: row;
          justify-content: flex-end; }
          .inquiry-detail-page-main .detail-main-box .left-box .sider-body-box .new-label-box .epc-box .epc-img-box {
            margin: 2px 12px; }
          .inquiry-detail-page-main .detail-main-box .left-box .sider-body-box .new-label-box .epc-box .iconfont {
            margin-right: 32px; }
        .inquiry-detail-page-main .detail-main-box .left-box .sider-body-box .new-label-box .icon-cancel-2 {
          position: absolute;
          right: 12px;
          top: 4px;
          font-size: 18px;
          color: red; }
    .inquiry-detail-page-main .detail-main-box .edit-box .epc-box-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #DDDDDD;
      border-bottom: 1px solid var(--border-deep);
      padding: 6px 12px; }
      .inquiry-detail-page-main .detail-main-box .edit-box .epc-box-wrap .img-box {
        width: 24px;
        height: 26px;
        border: 1px solid #DDDDDD;
        border: 1px solid var(--border-deep);
        margin-right: 10px; }
      .inquiry-detail-page-main .detail-main-box .edit-box .epc-box-wrap .img-box-wrap {
        display: flex; }
    .inquiry-detail-page-main .detail-main-box .edit-box .icon-icon-edit-1 {
      color: #FF3838;
      color: var(--color-hint);
      font-size: 24px; }
      .inquiry-detail-page-main .detail-main-box .edit-box .icon-icon-edit-1.grey {
        color: #999; }
    .inquiry-detail-page-main .detail-main-box .edit-box .grey {
      color: #999; }
    .inquiry-detail-page-main .detail-main-box .edit-box .icon-goods-tag-epc-n {
      font-size: 43px;
      color: #999; }
    .inquiry-detail-page-main .detail-main-box .edit-box.active {
      background-color: #fff; }
    .inquiry-detail-page-main .detail-main-box .edit-box:hover {
      cursor: pointer; }
    .inquiry-detail-page-main .detail-main-box .single-edit-box {
      padding: 16px;
      border-top: 1px solid #DDDDDD;
      border-top: 1px solid var(--border-deep); }
    .inquiry-detail-page-main .detail-main-box .right-box {
      flex: 1 1;
      width: 0; }
  .inquiry-detail-page-main .inquiry-product-list-box {
    margin-bottom: 16px;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep); }
    .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head {
      background: #f8f8f8;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #333;
      color: var(--font-title);
      padding: 0 16px;
      border-bottom: 1px solid #DDDDDD;
      border-bottom: 1px solid var(--border-deep); }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 32px; }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head .epc-btn {
        vertical-align: middle;
        display: inline-block;
        line-height: 1;
        cursor: pointer;
        margin-right: 0; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head .epc-btn .svg-icon {
          width: 36px;
          height: 36px; }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head .txt-tag {
        display: inline-block;
        vertical-align: middle;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        margin-right: 4px;
        font-size: 12px;
        background-color: #FF3838;
        background-color: var(--font-high);
        color: #fff; }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head .txt-hollow {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        width: 20px;
        line-height: 20px;
        margin-right: 4px;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #FF3838;
        border: 1px solid var(--font-high);
        color: #FF3838;
        color: var(--font-high); }
    .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main {
      min-height: 220px; }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box {
        display: flex;
        padding: 16px;
        border-top: 1px solid #DDDDDD;
        border-top: 1px solid var(--border-deep);
        font-size: 14px;
        width: 100%; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box:first-child {
          border: 0 none; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box {
          flex: 1 1; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tit {
            color: #333;
            color: var(--font-title); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tips span {
            margin-right: 8px; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tips .iconfont {
            font-size: 14px;
            color: #FF3838;
            color: var(--font-high);
            margin-right: 4px; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .price-box {
          width: 20%;
          color: #FF3838;
          color: var(--font-high); }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart {
          width: 12%;
          text-align: right;
          cursor: pointer;
          color: #333;
          color: var(--font-title); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart.active {
            color: #FF3838;
            color: var(--font-high); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart .iconfont {
            font-size: 14px;
            margin-right: 4px; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .remark {
          padding: 4px 0;
          color: #FF3838;
          color: var(--font-high); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .remark .tag {
            border: 1px solid #33cccc;
            background-color: rgba(51, 204, 204, 0.2);
            color: #33cccc;
            padding: 0 8px;
            border-radius: 2px;
            margin-right: 8px; }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box {
        text-align: center;
        padding-top: 40px; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap {
          display: inline-block; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .icon-box .svg-icon {
            width: 90px;
            height: 90px; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .tips-text {
            font-size: 14px;
            color: #666;
            color: var(--font-normal); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .status-box {
            width: 102px;
            height: 24px;
            border: 1px solid #999;
            border: 1px solid var(--font-tips);
            text-align: center;
            line-height: 22px;
            font-size: 14px;
            color: #666;
            color: var(--font-normal);
            border-radius: 4px;
            margin-top: 8px; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .time {
            margin-top: 8px;
            font-size: 14px;
            color: #FF3838;
            color: var(--font-high); }
  .inquiry-detail-page-main .parts-remark-box {
    border-right: 0;
    height: 540px !important;
    border: 0px solid #DDDDDD;
    border: 0px solid var(--border-deep); }
    .inquiry-detail-page-main .parts-remark-box .parts-remark-box-head {
      background: #f8f8f8;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #333;
      color: var(--font-title);
      padding: 0 16px;
      border-bottom: 1px solid #DDDDDD;
      border-bottom: 1px solid var(--border-deep); }
    .inquiry-detail-page-main .parts-remark-box .parts-remark-box-body {
      min-height: 200px;
      padding: 16px; }
      .inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .remark-wrap {
        font-size: 14px;
        color: #333;
        color: var(--font-title);
        margin-bottom: 8px;
        word-break: break-all;
        white-space: normal; }
      .inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .img-wrap .img-box {
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;
        width: 144px;
        height: 144px;
        border: 1px solid #DDDDDD;
        border: 1px solid var(--border-deep);
        text-align: center;
        line-height: 144px; }
        .inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .img-wrap .img-box img {
          max-height: 100%;
          max-width: 100%; }
    .inquiry-detail-page-main .parts-remark-box .part-item {
      display: inline-block;
      padding: 0px 16px;
      vertical-align: top;
      height: 36px;
      line-height: 36px;
      background: #f5f5f5;
      border-radius: 18px;
      margin-right: 12px;
      margin-bottom: 12px;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      overflow: hidden;
      color: #333; }
      .inquiry-detail-page-main .parts-remark-box .part-item.active {
        color: #FF3838;
        color: var(--color-hint); }
    .inquiry-detail-page-main .parts-remark-box .part-item:hover {
      cursor: pointer; }

.loading-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.key-word-search-result {
  margin-right: 8px;
  border-bottom: 1px solid #999;
  display: flex;
  justify-content: space-between; }
  .key-word-search-result .goods-card-wrap-head {
    width: 100%;
    padding: 6px 12px 6px 0;
    font-size: 14px;
    background: #fff;
    cursor: pointer;
    display: flex; }
    .key-word-search-result .goods-card-wrap-head .detail-box {
      flex: 1 1; }
      .key-word-search-result .goods-card-wrap-head .detail-box .oe-name {
        font-weight: 700; }
      .key-word-search-result .goods-card-wrap-head .detail-box .oe-number {
        font-size: 12px; }
      .key-word-search-result .goods-card-wrap-head .detail-box b {
        color: #FF3838;
        margin-right: 3px; }
    .key-word-search-result .goods-card-wrap-head .img-box {
      width: 40px;
      height: 40px;
      text-align: center;
      margin-left: 8px;
      border: 1px solid #666; }
    .key-word-search-result .goods-card-wrap-head .result-detail-box .tips {
      color: #20ADE5;
      font-weight: 700;
      margin-bottom: 8px; }
    .key-word-search-result .goods-card-wrap-head .result-detail-img-box {
      display: flex; }
      .key-word-search-result .goods-card-wrap-head .result-detail-img-box .img-box {
        display: flex;
        margin-left: 0; }
      .key-word-search-result .goods-card-wrap-head .result-detail-img-box .img-box:nth-child(1) {
        margin-right: 9px; }
  .key-word-search-result .item-list-card {
    width: 100%;
    align-items: center;
    line-height: 30px;
    padding: 7px 0;
    margin: 0 12px;
    font-size: 14px;
    background: #fff;
    white-space: normal;
    text-overflow: inherit;
    overflow: visible; }
    .key-word-search-result .item-list-card .name {
      flex: 1 1; }
    .key-word-search-result .item-list-card .tips {
      color: #999;
      color: var(--font-tips); }
    .key-word-search-result .item-list-card .alias-name {
      display: block;
      color: #999;
      color: var(--font-tips);
      font-size: 12px;
      line-height: 1.3; }
  .key-word-search-result .alias-name-opt {
    position: relative; }
    .key-word-search-result .alias-name-opt .more-alias {
      position: absolute;
      width: 60px;
      right: 0;
      padding-top: 36px;
      line-height: 1.3;
      font-size: 12px; }

.inquiry-create-page-main .detail-main-box .left-box {
  width: 811px;
  height: 560px; }
  .inquiry-create-page-main .detail-main-box .left-box .title {
    font-size: 14px;
    background: #f8f8f8;
    padding: 12px 0 12px 24px; }
  .inquiry-create-page-main .detail-main-box .left-box .sider-body-box {
    width: 100%;
    background-color: #f8f8f8; }
    .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box {
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 6px 12px;
      font-size: 12px;
      color: #333;
      text-align: center;
      border: 0px solid #e3e3e3;
      border-radius: 3px;
      background-color: #fff;
      margin: 1px 24px 0;
      justify-content: space-between; }
      .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .oe-name {
        margin-bottom: 6px;
        text-align: left; }
      .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .icon-cancel-2 {
        right: 12px;
        top: 4px;
        font-size: 18px;
        color: red; }
      .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .icon-cancel-2:hover {
        cursor: pointer; }
      .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .icon-my-menu-7 {
        color: #FF3838;
        color: var(--color-hint); }
        .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .icon-my-menu-7.grey {
          color: #999; }
      .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .epc-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end; }
        .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .epc-box .epc-img-box {
          display: flex;
          width: 20px;
          height: 20px;
          cursor: pointer; }
          .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .epc-box .epc-img-box img {
            width: 100%;
            height: 100%;
            margin-right: 4px;
            border: 1px solid #e3e3e3; }
        .inquiry-create-page-main .detail-main-box .left-box .sider-body-box .new-label-box .epc-box .iconfont {
          cursor: pointer; }

.remark-pop-body .text-area-wrap {
  margin-bottom: 16px; }

.client-info-modal .image-upload {
  margin-left: auto;
  justify-content: flex-end; }

.gold-price {
  color: #000; }
  .gold-price span:nth-of-type(2) {
    font-size: 14px;
    margin-left: 12px;
    padding: 0 3px 0 3px;
    color: #FFE0BA;
    background-color: #232120;
    border-radius: 5px; }

@media all and (max-height: 768px) {
  .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box {
    width: 240px;
    height: 240px;
    line-height: 240px; } }

.goods-list-wrap {
  width: 1200px;
  min-width: 1200px;
  margin: 10px auto;
  background: #fff; }
  .goods-list-wrap .ant-pagination {
    padding: 32px 0 8px;
    text-align: center; }
  .goods-list-wrap .ant-spin {
    display: block;
    padding: 48px 0 36px; }
  .goods-list-wrap .ant-empty {
    padding: 48px 0 36px; }

.goods-list-row {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep); }
  .goods-list-row .check-box {
    width: 20px;
    margin-right: 10px;
    align-self: center;
    cursor: pointer; }
    .goods-list-row .check-box:hover {
      color: #DC3848;
      color: var(--color); }
    .goods-list-row .check-box .icon-check-s {
      color: #DC3848;
      color: var(--color); }
  .goods-list-row .image-box {
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer; }
    .goods-list-row .image-box .tips-box {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 32px;
      font-size: 14px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.8); }
  .goods-list-row .detail-box {
    width: 348px;
    padding-left: 24px;
    word-break: break-all;
    cursor: pointer;
    position: relative; }
    .goods-list-row .detail-box .name {
      font-size: 14px; }
    .goods-list-row .detail-box .tips {
      font-size: 12px;
      color: #999;
      color: var(--font-tips); }
    .goods-list-row .detail-box .ant-select {
      margin-top: 12px; }
  .goods-list-row .describe-box {
    width: 336px;
    padding-left: 24px; }
    .goods-list-row .describe-box .price {
      font-size: 16px;
      color: #FF3838;
      color: var(--font-high); }
    .goods-list-row .describe-box .unit {
      font-size: 12px;
      color: #999;
      color: var(--font-tips); }
    .goods-list-row .describe-box .gold-price {
      color: #000; }
      .goods-list-row .describe-box .gold-price span:nth-of-type(2) {
        font-size: 14px;
        margin-left: 12px;
        padding: 0 3px 0 3px;
        color: #FFE0BA;
        background-color: #232120;
        border-radius: 5px; }
    .goods-list-row .describe-box .row {
      color: #666;
      color: var(--font-normal); }
    .goods-list-row .describe-box .icon-goods-tag-delivery,
    .goods-list-row .describe-box .icon-goods-tag-shipments {
      color: #FF3838;
      color: var(--font-high);
      margin-right: 4px; }
  .goods-list-row .cart-box {
    width: 246px;
    padding-left: 24px; }
    .goods-list-row .cart-box .stock {
      font-size: 14px;
      color: #999;
      color: var(--font-tips); }
    .goods-list-row .cart-box .label {
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
      padding: 0 8px;
      color: #333;
      color: var(--font-title);
      border-radius: 12px;
      background: #F5F5F5; }
  .goods-list-row .operate-box {
    align-self: center; }
    .goods-list-row .operate-box .btn {
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      border: 0 none;
      border-radius: 4px;
      background: #DC3848;
      background: var(--color);
      color: #fff;
      cursor: pointer; }
      .goods-list-row .operate-box .btn:hover {
        opacity: .8; }
      .goods-list-row .operate-box .btn:disabled {
        background: #b3b3b3;
        background: var(--disabled); }

.goods-detail-head {
  display: flex;
  padding: 20px 16px; }
  .goods-detail-head .goods-detail-box {
    flex: 1 1;
    padding-left: 32px; }
    .goods-detail-head .goods-detail-box .name {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 600;
      color: #333;
      color: var(--font-title); }
    .goods-detail-head .goods-detail-box .code {
      color: #999;
      color: var(--font-tips); }
      .goods-detail-head .goods-detail-box .code .item {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        word-break: break-word; }
      .goods-detail-head .goods-detail-box .code .icon-shuoming {
        margin-right: 2px; }
  .goods-detail-head .coupon-box {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 16px;
    border-bottom: 2px dashed #e3e3e3;
    background: whitesmoke; }
    .goods-detail-head .coupon-box .label {
      padding-right: 8px; }
    .goods-detail-head .coupon-box .more-btn {
      cursor: pointer; }
    .goods-detail-head .coupon-box .icon-jump {
      font-size: 12px; }
    .goods-detail-head .coupon-box .coupon-list-box {
      flex: 1 1;
      overflow: hidden;
      height: 20px;
      padding: 1px 0; }
    .goods-detail-head .coupon-box .coupon-label {
      align-items: center;
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      line-height: 18px;
      color: #FF3838;
      color: var(--color-hint);
      position: relative;
      margin: 0 4px 0 2px;
      padding: 0 8px;
      border-radius: 2px;
      background: radial-gradient(circle at left, transparent 4px, #FFEFF0 0) left/60% no-repeat, radial-gradient(circle at right, transparent 4px, #FFEFF0 0) right/60% no-repeat;
      -webkit-filter: drop-shadow(0 0 1px #FF3838);
              filter: drop-shadow(0 0 1px #FF3838);
      -webkit-filter: drop-shadow(0 0 1px var(--color-hint));
              filter: drop-shadow(0 0 1px var(--color-hint)); }

.goods-product-box {
  margin-top: 32px; }

.goods-product-item {
  display: flex;
  margin-bottom: 32px;
  font-size: 14px;
  color: #999;
  color: var(--font-tips); }
  .goods-product-item .detail-box {
    width: 340px;
    padding-right: 16px; }
    .goods-product-item .detail-box .format {
      line-height: 24px;
      color: #666;
      color: var(--font-normal); }
      .goods-product-item .detail-box .format .code {
        color: #999;
        color: var(--font-tips); }
    .goods-product-item .detail-box .icon-spec-picture {
      color: #999;
      color: var(--font-tips);
      margin-right: 4px;
      font-size: 14px; }
      .goods-product-item .detail-box .icon-spec-picture:hover {
        opacity: .8;
        cursor: pointer; }
    .goods-product-item .detail-box .iconimg {
      color: #333;
      color: var(--font-title); }
  .goods-product-item .describe-box {
    margin-top: 8px; }
    .goods-product-item .describe-box .row {
      padding-right: 24px; }
    .goods-product-item .describe-box .iconfont {
      color: #FF3838;
      color: var(--font-high); }
  .goods-product-item .price-box {
    width: 120px; }
    .goods-product-item .price-box .price {
      font-size: 16px;
      color: #FF3838;
      color: var(--font-high); }
      .goods-product-item .price-box .price .unit {
        font-size: 12px;
        color: #999;
        color: var(--font-tips); }
  .goods-product-item .uint-box {
    margin-top: 8px; }
  .goods-product-item .cart-box {
    width: 126px; }
    .goods-product-item .cart-box .stock {
      margin-top: 8px; }
  .goods-product-item .operate-box {
    align-self: flex-end; }
    .goods-product-item .operate-box .btn {
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      border: 0 none;
      border-radius: 4px;
      background: #DC3848;
      background: var(--color);
      color: #fff;
      cursor: pointer; }
      .goods-product-item .operate-box .btn:hover {
        opacity: .8; }
      .goods-product-item .operate-box .btn:disabled {
        background: #b3b3b3;
        background: var(--disabled); }

.goods-detail-introduce {
  width: 800px;
  margin: 24px auto 0;
  word-break: break-all;
  white-space: normal; }

.goods-detail-property {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px; }
  .goods-detail-property .item {
    display: inline-block;
    width: 294px;
    padding-right: 120px;
    vertical-align: top;
    font-size: 14px;
    margin-bottom: 8px;
    color: #666;
    color: var(--font-normal); }

.goods-detail-tabs .ant-tabs-nav-container {
  height: 54px;
  padding-top: 8px;
  padding-left: 16px;
  background: #F5F5F5; }
  .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab:hover, .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab:active {
    color: #DC3848;
    color: var(--color); }
  .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab-active {
    color: #DC3848;
    color: var(--color); }
  .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-ink-bar {
    background-color: #DC3848;
    background-color: var(--color); }

.goods-detail-tabs .ant-pagination {
  margin: 62px auto 24px;
  text-align: center; }

.goods-detail-tabs .ant-empty {
  margin: 48px 0 24px; }

.goods-activity-head {
  padding: 16px 0;
  margin-bottom: 16px;
  background-color: #fff; }
  .goods-activity-head .goods-activity-wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 0 16px; }
    .goods-activity-head .goods-activity-wrap .breadcrumb-block-main {
      padding: 0; }
  .goods-activity-head .title {
    font-size: 16px;
    color: #FF3838;
    color: var(--color-hint); }
  .goods-activity-head .info {
    font-size: 12px;
    color: #999;
    color: var(--font-tips); }

.cart-page-wrap {
  width: 1200px;
  min-width: 1200px;
  min-height: 400px;
  padding: 12px 14px 64px;
  margin: 0 auto;
  background: #fff; }
  .cart-page-wrap .ant-spin {
    display: block;
    padding: 48px 0 36px; }
  .cart-page-wrap .ant-empty {
    padding: 48px 0 36px; }

.cart-table .cart-table-head {
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  background: #F5F5F5; }
  .cart-table .cart-table-head .ant-checkbox-wrapper {
    padding: 0 10px; }
  .cart-table .cart-table-head .item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding: 10px; }

.cart-table .cart-table-body-head {
  line-height: 32px;
  margin-top: 8px;
  font-size: 14px;
  border-bottom: 2px solid #666;
  color: #333;
  color: var(--font-title); }
  .cart-table .cart-table-body-head .col {
    margin: 0 12px; }
    .cart-table .cart-table-body-head .col .time,
    .cart-table .cart-table-body-head .col .icon-goods-tag-delivery,
    .cart-table .cart-table-body-head .col .icon-goods-tag-shipments {
      color: #FF3838;
      color: var(--font-high); }
  .cart-table .cart-table-body-head .ant-checkbox-wrapper {
    padding: 10px;
    margin-right: 10px; }

.cart-table .cart-table-body-tr {
  border: 1px solid #E3E3E3;
  padding: 4px 0 6px;
  margin-top: -1px; }
  .cart-table .cart-table-body-tr .tr-expand {
    padding-left: 10px; }
    .cart-table .cart-table-body-tr .tr-expand .iconfont {
      color: #DC3848;
      color: var(--color);
      margin-right: 8px; }
    .cart-table .cart-table-body-tr .tr-expand .col {
      margin-right: 16px;
      color: #999;
      color: var(--font-tips); }
    .cart-table .cart-table-body-tr .tr-expand .remark-input {
      width: 390px; }
  .cart-table .cart-table-body-tr .tr-expand-check {
    padding-left: 46px; }
  .cart-table .cart-table-body-tr .tr-body {
    display: flex; }
    .cart-table .cart-table-body-tr .tr-body .ant-checkbox-wrapper {
      padding: 10px; }
    .cart-table .cart-table-body-tr .tr-body .check-hint {
      margin: 10px 0 0 6px;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      width: 30px;
      text-align: center;
      border-radius: 8px;
      color: #fff;
      background-color: #999; }
    .cart-table .cart-table-body-tr .tr-body .item {
      flex: 1 1 auto;
      padding: 10px;
      word-break: break-word; }
    .cart-table .cart-table-body-tr .tr-body .price {
      color: #FF3838;
      color: var(--font-high); }
    .cart-table .cart-table-body-tr .tr-body .text-btn {
      border: 0 none;
      cursor: pointer;
      color: #333;
      color: var(--font-title);
      background: transparent; }

.page-cart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  height: 48px;
  padding: 0 0 0 24px;
  border-top: 1px solid #DDDDDD;
  border-top: 1px solid var(--border-deep);
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  background: #FCFCFC; }
  .page-cart-footer .opt-btn {
    font-size: 14px;
    color: #666;
    color: var(--font-normal);
    margin: 0 16px 0 24px;
    background: transparent;
    border: 0 none;
    cursor: pointer; }
    .page-cart-footer .opt-btn:hover {
      color: #333;
      color: var(--font-title); }
  .page-cart-footer .btn {
    width: 114px;
    height: 48px;
    font-size: 16px;
    color: #fff;
    margin-left: 24px;
    border: 0 none;
    background: #DC3848;
    background: var(--color); }
    .page-cart-footer .btn:hover, .page-cart-footer .btn:focus {
      cursor: pointer;
      background: rgba(231, 47, 62, 0.8);
      background: var(--color-active); }
    .page-cart-footer .btn:disabled {
      background: #b3b3b3;
      background: var(--disabled);
      cursor: not-allowed; }
  .page-cart-footer .text {
    margin-right: 24px;
    font-size: 14px;
    color: #666;
    color: var(--font-normal); }
    .page-cart-footer .text .hint {
      font-size: 18px;
      font-weight: 700;
      color: #FF3838;
      color: var(--font-high); }

.page-crumb {
  line-height: 58px;
  padding: 0 24px;
  font-size: 14px;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  color: #333;
  color: var(--font-title); }
  .page-crumb .iconfont {
    font-size: 12px; }

.order-create-wrap {
  padding: 0 14px; }

.create-order-headline {
  height: 50px;
  padding-top: 18px;
  line-height: 32px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #333;
  color: var(--font-title);
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep); }
  .create-order-headline:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 14px;
    margin-right: 6px;
    background: #333;
    background: var(--font-title); }
  .create-order-headline .txt-btn {
    float: right;
    height: 32px;
    font-size: 14px;
    border: 0 none;
    background: transparent;
    color: #DC3848;
    color: var(--color); }

.create-order-expand {
  display: flex;
  justify-content: space-between;
  padding: 16px 0 0; }
  .create-order-expand .create-order-radio .row {
    margin-bottom: 10px;
    font-size: 14px; }
  .create-order-expand .create-order-radio .tax-row {
    margin-left: 70px;
    font-size: 14px; }
    .create-order-expand .create-order-radio .tax-row .btn {
      margin-left: 1em;
      color: #1A81E8;
      cursor: pointer; }
  .create-order-expand .create-order-radio .label {
    min-width: 5em;
    display: inline-block;
    padding-right: 14px; }
  .create-order-expand .create-order-radio .tips {
    color: #999;
    color: var(--font-tips); }
  .create-order-expand .create-order-radio .select-tag {
    cursor: pointer; }
    .create-order-expand .create-order-radio .select-tag .tag {
      font-size: 12px;
      line-height: 16px;
      padding: 0 6px;
      border-radius: 2px;
      color: #FF3838;
      color: var(--font-high);
      border: 1px solid #FF3838;
      border: 1px solid var(--color-hint); }
    .create-order-expand .create-order-radio .select-tag .icon-jump {
      font-size: 12px;
      margin-left: 4px;
      color: #FF3838;
      color: var(--font-high); }
  .create-order-expand .create-order-total td:first-child {
    text-align: right;
    padding-right: 8px; }
  .create-order-expand .create-order-total .price {
    font-size: 18px;
    color: #FF3838;
    color: var(--font-high); }
  .create-order-expand .create-order-total .icon-shuoming {
    color: #F5A623;
    margin-right: 2px;
    font-size: 16px;
    cursor: pointer; }

.create-order-address {
  display: flex;
  flex: 0 1;
  flex-wrap: wrap; }
  .create-order-address .ant-empty {
    width: 100%; }
  .create-order-address .address-card {
    position: relative;
    width: 350px;
    height: 168px;
    padding: 0 16px;
    margin: 0 24px 16px 0;
    font-size: 14px;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    border-radius: 4px;
    cursor: pointer; }
    .create-order-address .address-card .icon-selected {
      position: absolute;
      right: -1px;
      bottom: -1px;
      line-height: 1;
      font-size: 18px;
      text-align: right;
      visibility: hidden;
      color: #DC3848;
      color: var(--color); }
    .create-order-address .address-card.address-card-selected {
      border: 2px solid #DC3848;
      border: 2px solid var(--color); }
      .create-order-address .address-card.address-card-selected .icon-selected {
        visibility: visible; }
    .create-order-address .address-card .head {
      line-height: 38px;
      margin-bottom: 12px;
      color: #333;
      color: var(--font-title);
      border-bottom: 1px solid #DDDDDD;
      border-bottom: 1px solid var(--border-deep); }
      .create-order-address .address-card .head .tips {
        float: right; }
    .create-order-address .address-card .body {
      color: #666;
      color: var(--font-normal);
      line-height: 22px; }
    .create-order-address .address-card .operate {
      position: absolute;
      right: 16px;
      bottom: 16px;
      font-size: 14px;
      color: #999;
      color: var(--font-tips); }
      .create-order-address .address-card .operate .btn:hover {
        color: #DC3848;
        color: var(--color); }

.create-order-upload {
  margin-top: 10px; }
  .create-order-upload .headline {
    font-size: 14px;
    margin-bottom: 8px;
    color: #999;
    color: var(--font-tips); }
  .create-order-upload .image-upload-btn {
    width: 80px;
    height: 80px;
    padding-bottom: 0; }

.order-pay-head {
  padding: 0 24px;
  margin-top: 12px;
  background: #fff; }
  .order-pay-head .headline {
    display: flex;
    justify-content: space-between;
    line-height: 78px;
    border-bottom: 1px solid #DDDDDD;
    border-bottom: 1px solid var(--border-deep); }
    .order-pay-head .headline .icon-fail1,
    .order-pay-head .headline .icon-succeed {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      vertical-align: middle; }
  .order-pay-head .row {
    padding: 12px 0;
    line-height: 38px;
    font-size: 14px;
    color: #666;
    color: var(--font-normal); }
    .order-pay-head .row .item {
      cursor: pointer; }
      .order-pay-head .row .item:hover {
        color: #DC3848;
        color: var(--color); }

.order-pay-page-main {
  background: #fff;
  padding: 0 24px;
  margin-top: 14px; }

.pay-money-box {
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep);
  background: #fff; }
  .pay-money-box .left-box .tit {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    color: #333;
    color: var(--font-title);
    margin-right: 16px; }
  .pay-money-box .left-box .txt {
    display: inline-block;
    vertical-align: middle;
    width: 240px;
    height: 42px;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    border-radius: 2px;
    overflow: hidden;
    position: relative; }
    .pay-money-box .left-box .txt:before {
      content: "￥";
      position: absolute;
      left: 6px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      font-size: 24px;
      color: #FF3838;
      color: var(--font-high);
      line-height: 1; }
    .pay-money-box .left-box .txt input {
      width: 100%;
      height: 100%;
      padding-left: 30px;
      border: 0 none;
      font-size: 24px;
      color: #FF3838;
      color: var(--font-high); }
  .pay-money-box .right-box {
    flex: 1 1;
    text-align: right; }
    .pay-money-box .right-box p {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      font-size: 14px;
      color: #333;
      color: var(--font-title);
      line-height: 1; }
      .pay-money-box .right-box p span {
        padding-left: 6px;
        color: #666;
        color: var(--font-normal); }
    .pay-money-box .right-box .time {
      color: #FF3838;
      color: var(--font-high); }
      .pay-money-box .right-box .time .iconfont {
        margin-right: 6px;
        font-size: 16px; }

.balance-pay-select {
  padding: 24px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep); }
  .balance-pay-select .left-box {
    flex: 1 1;
    font-size: 14px;
    color: #333;
    color: var(--font-title); }
    .balance-pay-select .left-box i {
      margin-right: 8px; }
  .balance-pay-select .right-box {
    flex: 1 1;
    text-align: right;
    font-size: 14px;
    color: #666;
    color: var(--font-normal); }

.pay-type-box {
  padding-top: 24px;
  min-height: 400px; }
  .pay-type-box li {
    margin-bottom: 30px;
    min-width: 200px;
    cursor: pointer; }
    .pay-type-box li .iconfont {
      display: inline-block;
      vertical-align: middle;
      color: #666;
      color: var(--font-normal); }
    .pay-type-box li .svg-icon {
      margin: 0 14px;
      width: 32px;
      height: 32px;
      vertical-align: middle; }
    .pay-type-box li .txt {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      color: #222; }
      .pay-type-box li .txt .balance {
        font-size: 18px;
        color: #666;
        color: var(--font-normal); }
    .pay-type-box li .bank-txt {
      display: inline-block;
      padding-left: 4px;
      vertical-align: middle;
      font-size: 12px;
      color: #DC3848;
      color: var(--color);
      cursor: pointer; }
      .pay-type-box li .bank-txt i {
        display: inline-block;
        margin-right: 4px; }
  .pay-type-box .btn-box .btn {
    display: inline-block;
    vertical-align: middle;
    width: 180px;
    height: 48px;
    background: #DC3848;
    background: var(--color);
    border-radius: 4px;
    border: 0 none;
    margin-right: 20px;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    color: #fff;
    cursor: pointer; }
    .pay-type-box .btn-box .btn:nth-child(2) {
      background: #fff;
      color: #DC3848;
      color: var(--color);
      border: 1px solid #DC3848;
      border: 1px solid var(--color); }
  .pay-type-box .order-pay-remark {
    padding: 0;
    border: 0 none;
    margin: 0; }
    .pay-type-box .order-pay-remark .txt-red {
      margin-bottom: 4px; }
    .pay-type-box .order-pay-remark .image-upload {
      margin-top: 12px; }

.page-order-pay-result {
  margin: 10px auto;
  padding: 22px 16px; }

.order-pay-result-head {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 32px 0;
  font-weight: 700;
  background: #EFFAEC; }
  .order-pay-result-head .svg-icon {
    width: 56px;
    height: 56px;
    margin-right: 16px; }

.order-pay-result-detail table {
  margin: 16px auto; }
  .order-pay-result-detail table td {
    padding: 2px; }

.order-pay-result-operate {
  text-align: center; }
  .order-pay-result-operate .btn {
    width: 116px;
    height: 32px;
    margin: 4px;
    font-size: 14px;
    color: #666;
    color: var(--font-normal);
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    background: transparent; }

.order-list-page {
  margin: 16px 0; }
  .order-list-page .ant-pagination {
    padding: 32px 0 8px;
    text-align: center; }
  .order-list-page .ant-spin {
    display: block;
    padding: 48px 0 36px; }
  .order-list-page .ant-empty {
    padding: 48px 0 36px; }
  .order-list-page .order-list-content {
    padding: 16px;
    min-height: 400px; }
  .order-list-page .order-list-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 8px; }
    .order-list-page .order-list-head .tabs-box .tab {
      display: inline-block;
      vertical-align: middle;
      margin: 0 14px;
      font-size: 14px;
      color: #666;
      color: var(--font-normal);
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;
      cursor: pointer; }
      .order-list-page .order-list-head .tabs-box .tab:hover {
        color: #DC3848;
        color: var(--color); }
      .order-list-page .order-list-head .tabs-box .tab.active {
        color: #DC3848;
        color: var(--color);
        border-color: #DC3848;
        border-color: var(--color); }
    .order-list-page .order-list-head .search-box .input-wrap, .order-list-page .order-list-head .search-box .select-box {
      display: inline-block;
      vertical-align: middle; }
    .order-list-page .order-list-head .search-box .input-wrap {
      margin-right: 8px; }
  .order-list-page .order-list-body table {
    width: 100%;
    font-size: 14px;
    color: #666;
    color: var(--font-normal);
    table-layout: fixed; }
    .order-list-page .order-list-body table thead tr {
      border: 1px solid #DDDDDD;
      border: 1px solid var(--border-deep); }
    .order-list-page .order-list-body table thead th {
      background: #f5f5f5;
      line-height: 40px;
      padding: 0 16px;
      text-align: center;
      width: 128px; }
      .order-list-page .order-list-body table thead th:nth-child(1) {
        text-align: left;
        width: 416px; }
      .order-list-page .order-list-body table thead th:nth-child {
        width: 162px; }
    .order-list-page .order-list-body table tbody:before {
      content: "";
      display: block;
      height: 16px; }
    .order-list-page .order-list-body table tbody tr:first-child {
      background-color: #f5f5f5;
      line-height: 40px;
      padding: 0 16px; }
    .order-list-page .order-list-body table tbody td {
      padding: 16px;
      text-align: center;
      border: 1px solid #DDDDDD;
      border: 1px solid var(--border-deep); }
      .order-list-page .order-list-body table tbody td:first-child {
        text-align: left;
        padding: 0; }
      .order-list-page .order-list-body table tbody td .ant-btn {
        margin-bottom: 8px; }
    .order-list-page .order-list-body table .col {
      display: inline-block;
      padding: 0 16px;
      margin-right: 32px;
      color: #999;
      color: var(--font-tips); }
    .order-list-page .order-list-body table .code {
      color: #666;
      color: var(--font-normal); }
    .order-list-page .order-list-body table .status {
      color: #999;
      color: var(--font-tips); }
    .order-list-page .order-list-body table .link {
      display: block;
      color: #333;
      color: var(--font-title); }
      .order-list-page .order-list-body table .link:hover {
        color: #DC3848;
        color: var(--color); }
    .order-list-page .order-list-body table .print-btn {
      background-color: transparent;
      border: 0 none;
      color: #333;
      color: var(--font-title); }
    .order-list-page .order-list-body table .text {
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all; }
    .order-list-page .order-list-body table .img-box {
      display: inline-block;
      width: 100px;
      height: 100px;
      text-align: center;
      line-height: 100px;
      position: relative; }
      .order-list-page .order-list-body table .img-box img {
        max-width: 100%;
        max-height: 100%; }
      .order-list-page .order-list-body table .img-box:after {
        content: attr(data-number);
        display: inline-block;
        width: 100%;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        left: 0;
        bottom: 0; }
    .order-list-page .order-list-body table .item-list {
      vertical-align: top; }
      .order-list-page .order-list-body table .item-list .item {
        display: flex;
        justify-content: space-between;
        padding: 22px 16px;
        border-bottom: 1px solid #DDDDDD;
        border-bottom: 1px solid var(--border-deep); }
        .order-list-page .order-list-body table .item-list .item:last-child {
          border-bottom: 0; }
        .order-list-page .order-list-body table .item-list .item .name {
          flex: 1 1;
          padding-right: 16px; }

.order-detail-page {
  padding: 16px;
  background: #fff; }
  .order-detail-page .ant-spin {
    width: 100%;
    text-align: center;
    margin: 48px 0; }

.order-detail-chunk {
  display: flex;
  padding: 16px 0;
  margin-bottom: 16px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep); }
  .order-detail-chunk .chunk-column {
    flex: 1 1;
    padding: 0 16px;
    border-left: 1px solid #DDDDDD;
    border-left: 1px solid var(--border-deep); }
    .order-detail-chunk .chunk-column:first-child {
      border: 0 none; }
  .order-detail-chunk .order-detail-code {
    flex: 0 0 260px; }
    .order-detail-chunk .order-detail-code .status {
      font-size: 24px;
      font-weight: 600;
      margin-top: 16px;
      margin-bottom: 8px;
      text-align: center;
      color: #333;
      color: var(--font-title); }
  .order-detail-chunk .order-detail-steps {
    display: flex;
    justify-content: center; }
    .order-detail-chunk .order-detail-steps .step {
      text-align: center;
      display: flex; }
      .order-detail-chunk .order-detail-steps .step:last-child .arrow {
        display: none; }
    .order-detail-chunk .order-detail-steps .step-active .iconfont {
      color: #DC3848;
      color: var(--color); }
    .order-detail-chunk .order-detail-steps .detail {
      width: 4em; }
    .order-detail-chunk .order-detail-steps .iconfont {
      font-size: 24px;
      color: #999;
      color: var(--font-tips); }
    .order-detail-chunk .order-detail-steps .icon-progress-bar {
      font-size: 17px; }
    .order-detail-chunk .order-detail-steps .label {
      display: block;
      color: #333;
      color: var(--font-title); }
    .order-detail-chunk .order-detail-steps .tips {
      display: inline-block;
      width: 70px;
      text-align: left;
      font-size: 12px;
      line-height: 1.3;
      color: #999;
      color: var(--font-tips); }
    .order-detail-chunk .order-detail-steps .arrow {
      padding-top: 4px; }
  .order-detail-chunk .order-log-btn {
    font-size: 14px;
    padding-top: 10px;
    padding-left: 42px;
    color: #666;
    color: var(--font-normal);
    cursor: pointer; }
  .order-detail-chunk dl dt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600; }
    .order-detail-chunk dl dt .btn-logistics {
      width: 80px;
      height: 24px;
      border: 1px solid #DC3848;
      border: 1px solid var(--color);
      color: #DC3848;
      color: var(--color);
      background: transparent;
      border-radius: 2px; }
      .order-detail-chunk dl dt .btn-logistics:hover {
        color: rgba(231, 47, 62, 0.8);
        color: var(--color-active);
        border-color: rgba(231, 47, 62, 0.8);
        border-color: var(--color-active); }
    .order-detail-chunk dl dt .btn-pay {
      width: 80px;
      height: 24px;
      border: 1px solid #DC3848;
      border: 1px solid var(--color);
      color: #DC3848;
      color: var(--color);
      background: transparent;
      border-radius: 2px; }
      .order-detail-chunk dl dt .btn-pay:hover {
        color: rgba(231, 47, 62, 0.8);
        color: var(--color-active);
        border-color: rgba(231, 47, 62, 0.8);
        border-color: var(--color-active); }
  .order-detail-chunk dl dd {
    display: flex;
    margin-bottom: 8px;
    font-size: 14px; }
    .order-detail-chunk dl dd .label {
      width: 70px;
      color: #666;
      color: var(--font-normal); }
    .order-detail-chunk dl dd .value {
      flex: 1 1;
      width: 0;
      padding-left: 16px;
      color: #333;
      color: var(--font-title); }
    .order-detail-chunk dl dd .img-box {
      display: inline-block;
      vertical-align: top;
      height: 70px;
      width: 70px;
      text-align: center;
      line-height: 70px;
      border: 1px solid #f5f5f5;
      margin-right: 4px;
      margin-bottom: 4px; }
      .order-detail-chunk dl dd .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .order-detail-chunk dl dd .tips {
      cursor: pointer; }

.order-detail-table table {
  width: 100%;
  font-size: 14px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep);
  color: #666;
  color: var(--font-normal); }
  .order-detail-table table th {
    padding: 0 16px;
    line-height: 40px;
    background: #f5f5f5; }
    .order-detail-table table th:nth-child(1) {
      width: 260px;
      color: #333;
      color: var(--font-title);
      font-weight: 600; }
    .order-detail-table table th:nth-child(2) {
      width: 156px; }
    .order-detail-table table th:nth-child(3) {
      width: 156px; }
    .order-detail-table table th:nth-child(4) {
      width: 90px; }
    .order-detail-table table th:nth-child(5) {
      width: 90px; }
    .order-detail-table table th:nth-child(6) {
      width: 120px; }
    .order-detail-table table th:nth-child(7) {
      width: 120px; }
    .order-detail-table table th:nth-child(8) {
      text-align: center; }
  .order-detail-table table td {
    padding: 16px;
    line-height: 22px;
    border-bottom: 1px solid #DDDDDD;
    border-bottom: 1px solid var(--border-deep); }
    .order-detail-table table td:nth-child(1) {
      color: #333;
      color: var(--font-title); }
    .order-detail-table table td[rowSpan] {
      border-left: 1px solid #DDDDDD;
      border-left: 1px solid var(--border-deep);
      border-right: 1px solid #DDDDDD;
      border-right: 1px solid var(--border-deep);
      text-align: center; }
  .order-detail-table table .opt-btn {
    background: transparent;
    border: 0 none; }
    .order-detail-table table .opt-btn.btn-disabled {
      color: #b3b3b3;
      color: var(--disabled); }

.order-total-table {
  border: 1px soild #DDDDDD;
  border: 1px soild var(--border-deep); }
  .order-total-table table {
    margin-left: auto; }
    .order-total-table table td {
      padding: 10px 12px 0; }
      .order-total-table table td:nth-child(1) {
        text-align: right; }
    .order-total-table table .total {
      font-size: 16px;
      font-weight: 600;
      color: #FF3838;
      color: var(--font-high); }

.back-edit-box {
  min-height: 400px; }
  .back-edit-box .goods-info-box {
    margin-bottom: 16px; }
    .back-edit-box .goods-info-box table {
      width: 100%;
      table-layout: fixed;
      font-size: 12px;
      color: #333;
      color: var(--font-title);
      font-weight: normal;
      text-align: left; }
    .back-edit-box .goods-info-box th {
      height: 32px;
      vertical-align: 32px;
      padding-right: 16px; }
    .back-edit-box .goods-info-box td {
      padding-right: 16px; }
      .back-edit-box .goods-info-box td .txt {
        display: inline-block;
        width: 100%; }
  .back-edit-box .remark-box .text-area {
    position: relative; }
    .back-edit-box .remark-box .text-area .ant-input-textarea-clear-icon {
      margin-right: 28px; }
    .back-edit-box .remark-box .text-area textarea {
      padding-bottom: 32px; }
    .back-edit-box .remark-box .text-area:after {
      content: attr(data-number);
      font-size: 14px;
      color: #999;
      color: var(--font-tips);
      position: absolute;
      right: 28px;
      bottom: 6px; }
  .back-edit-box .remark-box .img-upload-box {
    margin-top: 16px; }
    .back-edit-box .remark-box .img-upload-box .title {
      margin-bottom: 8px;
      font-size: 14px;
      color: #999;
      color: var(--font-tips); }

.back-result-box {
  margin-top: 40px;
  padding-top: 16px;
  border-top: 1px solid #DDDDDD;
  border-top: 1px solid var(--border-deep); }
  .back-result-box li {
    font-size: 14px;
    color: #333;
    color: var(--font-title);
    margin-bottom: 4px; }
    .back-result-box li span:first-child {
      color: #666;
      color: var(--font-normal); }

.order-detail-out {
  margin-bottom: 16px;
  border: 1px solid #DDDDDD;
  border: 1px solid var(--border-deep); }
  .order-detail-out .headline {
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: #666;
    color: var(--font-normal);
    background: #f5f5f5; }
  .order-detail-out .out-list-item {
    display: flex;
    padding: 8px 16px;
    border-bottom: 1px solid #DDDDDD;
    border-bottom: 1px solid var(--border-deep); }
    .order-detail-out .out-list-item:last-child {
      border-bottom: 0 none; }
    .order-detail-out .out-list-item .img-box {
      position: relative;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 48px;
      overflow: hidden; }
      .order-detail-out .out-list-item .img-box .tips {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 12px;
        line-height: 22px; }
    .order-detail-out .out-list-item .detail-box {
      padding: 0 16px;
      flex: 1 1;
      width: 0; }
      .order-detail-out .out-list-item .detail-box .name {
        color: #333;
        color: var(--font-title); }
      .order-detail-out .out-list-item .detail-box .tips {
        color: #999;
        color: var(--font-tips); }
    .order-detail-out .out-list-item .operate-box {
      align-self: center; }
  .order-detail-out .btn-logistics {
    width: 80px;
    height: 24px;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    margin-right: 8px;
    color: #333;
    color: var(--font-title);
    background: transparent;
    border-radius: 2px; }
    .order-detail-out .btn-logistics:hover {
      color: rgba(231, 47, 62, 0.8);
      color: var(--color-active);
      border-color: rgba(231, 47, 62, 0.8);
      border-color: var(--color-active); }
  .order-detail-out .btn-receive {
    width: 80px;
    height: 24px;
    border: 1px solid #DC3848;
    border: 1px solid var(--color);
    color: #DC3848;
    color: var(--color);
    background: transparent;
    border-radius: 2px; }
    .order-detail-out .btn-receive:hover {
      color: rgba(231, 47, 62, 0.8);
      color: var(--color-active);
      border-color: rgba(231, 47, 62, 0.8);
      border-color: var(--color-active); }

.order-log-page {
  padding: 16px;
  background: #fff; }
  .order-log-page table {
    width: 100%;
    font-size: 14px;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    color: #666;
    color: var(--font-normal); }
    .order-log-page table th {
      padding: 0 16px;
      line-height: 40px;
      background: #f5f5f5; }
      .order-log-page table th:nth-child(1) {
        width: 250px;
        font-weight: 600; }
      .order-log-page table th:nth-child(2) {
        width: 680px; }
    .order-log-page table td {
      padding: 0 16px;
      line-height: 40px;
      border-bottom: 1px solid #DDDDDD;
      border-bottom: 1px solid var(--border-deep); }

.pay-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 600px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .pay-modal .pay-modal-title {
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    color: #333;
    color: var(--font-title);
    background: #fcfcfc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #DDDDDD;
    border-bottom: 1px solid var(--border-deep); }
    .pay-modal .pay-modal-title .close {
      float: right;
      cursor: pointer;
      margin-right: 24px; }
      .pay-modal .pay-modal-title .close .iconfont {
        font-size: 15px; }
  .pay-modal .pay-modal-body {
    background: #fff;
    border-radius: 0 0 4px 4px; }
    .pay-modal .pay-modal-body .pay-modal-body-head {
      padding: 12px;
      border-bottom: 1px solid #DDDDDD;
      border-bottom: 1px solid var(--border-deep);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      width: 100%; }
    .pay-modal .pay-modal-body .pau-modal-body-main {
      padding: 48px 0;
      text-align: center; }
      .pay-modal .pay-modal-body .pau-modal-body-main .main-box {
        display: inline-block; }
        .pay-modal .pay-modal-body .pau-modal-body-main .main-box .price-box {
          font-size: 18px;
          font-weight: bold;
          color: #ff3838;
          color: var(--orangey-red); }
        .pay-modal .pay-modal-body .pau-modal-body-main .main-box .qrcode-box {
          padding: 2px;
          border: 1px solid #DDDDDD;
          border: 1px solid var(--border-deep);
          line-height: 1; }
        .pay-modal .pay-modal-body .pau-modal-body-main .main-box .tips-box {
          margin-top: 12px;
          line-height: 1.3;
          font-size: 12px;
          color: #666;
          color: var(--font-normal); }

.user-page-box {
  padding: 16px;
  background: #fff;
  display: flex;
  height: 100%; }
  .user-page-box .left-box {
    flex: 1 1; }
  .user-page-box .user-info-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px; }
    .user-page-box .user-info-card .info-box {
      padding: 10px;
      background: #F8F8F8;
      display: flex; }
      .user-page-box .user-info-card .info-box .user-card {
        flex: 1 1; }
      .user-page-box .user-info-card .info-box .account-box {
        width: 40%; }
    .user-page-box .user-info-card .order-statistic-box {
      height: 84px; }
      .user-page-box .user-info-card .order-statistic-box .statistic-box-card {
        height: 100%; }
  .user-page-box .info-list-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px; }
    .user-page-box .info-list-card .info-list-card-head {
      height: 40px;
      line-height: 40px;
      background: #f8f8f8;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      color: #666;
      color: var(--font-normal);
      padding: 0 16px; }
    .user-page-box .info-list-card .info-list-card-main .null-tips-box {
      padding: 40px 0; }
    .user-page-box .info-list-card .info-list-card-main .list-card-box {
      padding: 16px;
      display: flex;
      align-content: center;
      align-items: center;
      border-bottom: 1px solid #f5f5f5; }
      .user-page-box .info-list-card .info-list-card-main .list-card-box:last-child {
        border-bottom: 0 none; }
      .user-page-box .info-list-card .info-list-card-main .list-card-box .img-box {
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        margin-right: 8px; }
        .user-page-box .info-list-card .info-list-card-main .list-card-box .img-box img {
          max-height: 100%;
          max-width: 100%; }
      .user-page-box .info-list-card .info-list-card-main .list-card-box .main-box {
        flex: 1 1;
        width: 0;
        line-height: 1;
        font-size: 12px;
        margin-right: 24px; }
        .user-page-box .info-list-card .info-list-card-main .list-card-box .main-box .text-title {
          color: #333;
          color: var(--font-title); }
        .user-page-box .info-list-card .info-list-card-main .list-card-box .main-box .text-tips {
          margin-top: 8px;
          color: #999;
          color: var(--font-tips); }
      .user-page-box .info-list-card .info-list-card-main .list-card-box .btn-box .btn {
        width: 64px;
        height: 24px;
        border-radius: 2px;
        border: 1px solid #dcdcdc;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        color: #333;
        color: var(--font-title);
        background: #fff; }
        .user-page-box .info-list-card .info-list-card-main .list-card-box .btn-box .btn:hover {
          color: #fff;
          background: #DC3848;
          background: var(--color); }
      .user-page-box .info-list-card .info-list-card-main .list-card-box .tips-box {
        font-size: 12px;
        color: #999;
        color: var(--font-tips); }
  .user-page-box .info-data-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px; }
    .user-page-box .info-data-card .info-data-card-head {
      height: 40px;
      line-height: 40px;
      background: #f8f8f8;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      color: #666;
      color: var(--font-normal);
      padding: 0 16px; }
    .user-page-box .info-data-card .info-data-card-body {
      padding: 16px;
      font-size: 12px;
      color: #666;
      color: var(--font-normal); }
      .user-page-box .info-data-card .info-data-card-body .row-card {
        margin-bottom: 10px; }
      .user-page-box .info-data-card .info-data-card-body .iconfont {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        font-size: 12px; }
  .user-page-box .right-box {
    width: 290px;
    margin-left: 16px; }

.account-details-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%; }
  .account-details-page-box .account-details-page-main {
    padding: 16px; }
  .account-details-page-box .account-details-page-main-head {
    margin-bottom: 8px; }
    .account-details-page-box .account-details-page-main-head .input-wrap {
      display: inline-block;
      vertical-align: middle;
      width: 260px;
      margin-right: 14px; }
    .account-details-page-box .account-details-page-main-head .select-box {
      display: inline-block;
      vertical-align: middle;
      width: 260px;
      margin-right: 14px; }
  .account-details-page-box .account-details-page-main-body .statistics-box {
    height: 96px;
    background: #f5f5f5;
    border: 1px solid #DDDDDD;
    border: 1px solid var(--border-deep);
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 16px; }
    .account-details-page-box .account-details-page-main-body .statistics-box .statistics-item {
      flex: 1 1;
      text-align: center; }
      .account-details-page-box .account-details-page-main-body .statistics-box .statistics-item .number {
        font-size: 20px;
        font-weight: bold; }
      .account-details-page-box .account-details-page-main-body .statistics-box .statistics-item .title {
        font-size: 12px;
        color: #999;
        color: var(--font-tips); }
  .account-details-page-box .account-details-page-main-foot {
    text-align: center;
    margin-top: 16px; }

.title-line-box {
  padding-left: 12px;
  position: relative;
  font-size: 14px;
  line-height: 28px;
  color: #333;
  color: var(--font-title);
  border-bottom: 1px solid #DDDDDD;
  border-bottom: 1px solid var(--border-deep); }
  .title-line-box:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 12px;
    background: #333;
    background: var(--font-title);
    position: absolute;
    left: 0;
    top: 8px; }

.account-page-box {
  margin-top: 14px;
  background: #fff; }
  .account-page-box .account-page-body {
    padding: 16px; }
    .account-page-box .account-page-body .title-line-box {
      margin-bottom: 16px; }
    .account-page-box .account-page-body .form-item-box {
      width: 400px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px; }

.notices-details-page-box {
  margin-top: 14px;
  background: #fff; }
  .notices-details-page-box .notices-details-page-body {
    padding: 16px; }
  .notices-details-page-box .attachment-list a {
    display: flex; }
  .notices-details-page-box .attachment-list .img-box {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px; }
    .notices-details-page-box .attachment-list .img-box img {
      max-width: 100%;
      max-height: 100%; }
    .notices-details-page-box .attachment-list .img-box .svg-icon {
      width: 100%;
      height: 100%; }
  .notices-details-page-box .attachment-list .name {
    flex: 1 1; }

.staff-page-box {
  margin-top: 14px;
  background: #fff; }
  .staff-page-box .staff-page-body {
    padding: 16px; }
    .staff-page-box .staff-page-body .add-btn-box {
      padding-bottom: 16px; }
      .staff-page-box .staff-page-body .add-btn-box .link-btn {
        border: 0 none;
        background: transparent;
        color: #DC3848;
        color: var(--color);
        font-size: 14px;
        margin-right: 16px;
        display: inline-block;
        vertical-align: middle; }
        .staff-page-box .staff-page-body .add-btn-box .link-btn[disabled] {
          color: #b3b3b3;
          color: var(--disabled); }
        .staff-page-box .staff-page-body .add-btn-box .link-btn .iconfont {
          margin-right: 4px;
          font-size: 14px; }
      .staff-page-box .staff-page-body .add-btn-box .tips {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        color: #999;
        color: var(--font-tips); }

.collect-list-box {
  margin-top: 14px;
  background: #fff;
  min-height: 100%; }
  .collect-list-box .collect-list-body {
    padding: 16px 0; }
    .collect-list-box .collect-list-body .filter-sheet-wrap {
      padding: 0; }
    .collect-list-box .collect-list-body .sort-sheet-box {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #DDDDDD;
      border-bottom: 1px solid var(--border-deep); }
      .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .btn {
        margin-left: 12px;
        display: inline-block;
        vertical-align: middle;
        width: auto;
        padding: 0 8px;
        font-size: 12px;
        color: #333;
        color: var(--font-title);
        background: #f5f5f5;
        border: 1px solid #DDDDDD;
        border: 1px solid var(--border-deep); }
      .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        color: #666;
        color: var(--font-normal);
        margin-left: 12px;
        cursor: pointer; }
        .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn:hover {
          color: #DC3848;
          color: var(--color); }
        .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn .iconfont {
          font-size: 14px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px; }
        .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn .icon-check-s {
          color: #DC3848;
          color: var(--color); }

.help-detail-page-box {
  display: flex;
  width: 100%; }
  .help-detail-page-box .notices-details-page-box {
    flex: 1 1; }

.coupon-list-page {
  position: relative;
  margin: 16px 0; }
  .coupon-list-page .coupon-center-btn {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 40px;
    font-size: 14px;
    padding-right: 24px;
    color: #333;
    color: var(--font-title);
    font-weight: 600;
    cursor: pointer; }
  .coupon-list-page .coupon-list-tabs {
    margin-top: 8px;
    padding: 14px;
    background-color: #fff; }
    .coupon-list-page .coupon-list-tabs .coupon-list-tabpane {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .coupon-list-page .coupon-list-tabs .coupon-list-tabpane .coupon-list-card:nth-child(3n) {
        margin-right: 0; }
      .coupon-list-page .coupon-list-tabs .coupon-list-tabpane .ant-empty {
        display: block;
        margin: 16px auto; }
  .coupon-list-page .coupon-list-card {
    width: 310px;
    margin-right: 16px;
    margin-bottom: 16px; }
    .coupon-list-page .coupon-list-card .info-box {
      position: relative;
      height: 124px;
      padding: 16px;
      color: #fff;
      background: radial-gradient(circle at left center, transparent 4px, #FF3838 0) left top/4px 12px repeat-y, linear-gradient(to left, #FF6969, #FF3838) 3px/303px no-repeat, radial-gradient(circle at right center, transparent 4px, #FF6969 0) right top/4px 12px repeat-y; }
      .coupon-list-page .coupon-list-card .info-box .price {
        font-size: 36px;
        line-height: 1; }
        .coupon-list-page .coupon-list-card .info-box .price:first-letter {
          font-size: 24px; }
        .coupon-list-page .coupon-list-card .info-box .price .target {
          font-size: 16px;
          margin-left: 8px; }
      .coupon-list-page .coupon-list-card .info-box .name {
        font-size: 18px;
        line-height: 1;
        margin: 8px 0; }
      .coupon-list-page .coupon-list-card .info-box .time {
        font-size: 14px;
        opacity: .85; }
      .coupon-list-page .coupon-list-card .info-box .tag {
        position: absolute;
        right: 16px;
        top: 0;
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
        border-radius: 0 0 6px 6px;
        color: #FF3838;
        background-color: #ffcdcd; }
      .coupon-list-page .coupon-list-card .info-box .status-icon {
        position: absolute;
        right: 8px;
        bottom: 8px; }
    .coupon-list-page .coupon-list-card .intro-box {
      margin-top: -1px;
      border: 1px solid #DDDDDD;
      border: 1px solid var(--border-deep);
      border-radius: 0 4px 4px 0; }
    .coupon-list-page .coupon-list-card .explain-box {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      margin: 16px 0;
      cursor: pointer; }
      .coupon-list-page .coupon-list-card .explain-box .label {
        color: #333;
        color: var(--font-title); }
      .coupon-list-page .coupon-list-card .explain-box .introduce {
        flex: 1 1;
        color: #666;
        color: var(--font-normal); }
      .coupon-list-page .coupon-list-card .explain-box .iconfont {
        font-size: 14px; }
    .coupon-list-page .coupon-list-card .coupon-btn {
      width: 128px;
      height: 24px;
      border-radius: 12px;
      background-color: white;
      border: 1px solid #DC3848;
      border: 1px solid var(--color);
      color: #DC3848;
      color: var(--color);
      text-align: center;
      margin: 0 auto 16px;
      cursor: pointer;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }
  .coupon-list-page .coupon-list-card-used .info-box,
  .coupon-list-page .coupon-list-card-expires .info-box {
    position: relative;
    height: 124px;
    padding: 16px;
    color: #000;
    background: radial-gradient(circle at left center, transparent 4px, #E3E3E3 0) left top/4px 12px repeat-y, linear-gradient(to left, #E3E3E3, #E3E3E3) 3px/303px no-repeat, radial-gradient(circle at right center, transparent 4px, #E3E3E3 0) right top/4px 12px repeat-y; }
    .coupon-list-page .coupon-list-card-used .info-box .tag,
    .coupon-list-page .coupon-list-card-expires .info-box .tag {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.45); }
  .coupon-list-page .ant-pagination {
    padding: 24px 0;
    background-color: #fff;
    text-align: center; }

.coupon-center-page .headline {
  text-align: center;
  height: 114px;
  line-height: 114px;
  background-color: #fff; }
  .coupon-center-page .headline .icon-title-1 {
    width: 316px;
    height: 40px; }

.coupon-center-page .coupon-center-main {
  width: 1200px;
  margin: 8px auto;
  padding: 24px;
  background-color: #fff; }
  .coupon-center-page .coupon-center-main .ant-pagination {
    padding-top: 24px;
    text-align: center; }

.coupon-center-page .coupon-center-list {
  display: flex;
  flex-wrap: wrap; }

.coupon-center-page .coupon-center-card {
  width: 374px;
  display: flex;
  margin-bottom: 16px;
  margin-right: 15px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2); }
  .coupon-center-page .coupon-center-card:nth-child(3n) {
    margin-right: 0; }
  .coupon-center-page .coupon-center-card .price-box {
    width: 120px;
    height: 108px;
    padding-top: 32px;
    text-align: center;
    color: #fff;
    background: radial-gradient(circle at left center, transparent 3px, #FF3838 0) left top/4px 12px repeat-y, linear-gradient(to left, #FF6969, #FF3838) 3px/118px no-repeat; }
    .coupon-center-page .coupon-center-card .price-box .money {
      font-size: 24px;
      line-height: 1; }
      .coupon-center-page .coupon-center-card .price-box .money:first-letter {
        font-size: 12px; }
    .coupon-center-page .coupon-center-card .price-box .target {
      line-height: 1;
      font-size: 12px;
      margin-top: 8px; }
  .coupon-center-page .coupon-center-card .info-box {
    display: flex;
    width: 255px;
    height: 108px;
    padding: 16px 0;
    border-radius: 0 2px 2px 0;
    background-color: white;
    border: 1px solid #e3e3e3; }
  .coupon-center-page .coupon-center-card .intro-box {
    width: 162px;
    padding: 0 12px 0 16px;
    border-right: 1px dashed #DDDDDD;
    border-right: 1px dashed var(--border-deep); }
    .coupon-center-page .coupon-center-card .intro-box .tag {
      display: inline-block;
      padding: 0 8px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 49px;
      background-color: #FF3838;
      background-color: var(--color-hint);
      color: #fff; }
    .coupon-center-page .coupon-center-card .intro-box .name {
      font-size: 14px;
      color: #333;
      color: var(--font-title);
      font-weight: 600; }
    .coupon-center-page .coupon-center-card .intro-box .time {
      font-size: 12px;
      color: #999;
      color: var(--font-tips); }
  .coupon-center-page .coupon-center-card .status-box {
    width: 92px;
    text-align: center; }
  .coupon-center-page .coupon-center-card .btn-box {
    text-align: center; }
    .coupon-center-page .coupon-center-card .btn-box .btn {
      font-size: 12px;
      line-height: 24px;
      padding: 0 8px;
      border-radius: 11px;
      border: 0 none;
      background-color: #FF3838;
      background-color: var(--color-hint);
      color: #fff; }

.coupon-center-page .coupon-card-use .price-box {
  background: #e3e3e3;
  color: #000; }
  .coupon-center-page .coupon-card-use .price-box .target {
    opacity: .65; }

.coupon-center-page .coupon-card-use .info-box .tag {
  color: #666;
  color: var(--font-normal);
  background-color: #e3e3e3; }

.im-chat-modal-box {
  position: fixed;
  right: 36px;
  bottom: 0;
  height: 602px;
  width: 522px;
  background: #fff;
  border: 1px solid var(--border-deep);
  display: flex;
  flex-direction: column;
  z-index: 999; }

.im-chat-modal-header-box {
  height: 80px;
  padding: 15px 120px 15px 24px;
  background-color: rgba(245, 245, 245, 0.7);
  position: relative; }
  .im-chat-modal-header-box .user-info-box {
    width: 100%;
    display: flex; }
    .im-chat-modal-header-box .user-info-box .user-avatar-box {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50%; }
      .im-chat-modal-header-box .user-info-box .user-avatar-box img {
        width: 100%;
        height: 100%; }
    .im-chat-modal-header-box .user-info-box .user-name-box {
      padding-left: 16px;
      font-size: 18px;
      color: var(--font-title); }
  .im-chat-modal-header-box .im-chat-operation-box {
    position: absolute;
    top: 15px;
    right: 24px; }
    .im-chat-modal-header-box .im-chat-operation-box .item {
      margin-left: 6px;
      cursor: pointer;
      font-size: 18px; }
      .im-chat-modal-header-box .im-chat-operation-box .item:hover, .im-chat-modal-header-box .im-chat-operation-box .item:active {
        opacity: .8; }

.im-chat-modal-body-box {
  flex: 1 1;
  overflow: auto;
  padding: 10px; }
  .im-chat-modal-body-box .ant-avatar {
    line-height: normal !important; }
  .im-chat-modal-body-box .avatar-box {
    width: 48px;
    height: 48px; }
    .im-chat-modal-body-box .avatar-box img {
      width: 100%;
      height: 100%; }
  .im-chat-modal-body-box .chat-item-box {
    width: 100%;
    margin-bottom: 12px; }
    .im-chat-modal-body-box .chat-item-box.chat-item-main-left .chat-item-main {
      margin-left: 10px; }
      .im-chat-modal-body-box .chat-item-box.chat-item-main-left .chat-item-main .chat-info-content {
        text-align: left;
        background: #fff;
        color: #333; }
    .im-chat-modal-body-box .chat-item-box.chat-item-main-right .chat-item-main {
      margin-right: 10px; }
    .im-chat-modal-body-box .chat-item-box.chat-item-main-custom .chat-item-main {
      width: 100%;
      max-width: 100%; }
      .im-chat-modal-body-box .chat-item-box.chat-item-main-custom .chat-item-main .chat-info-content {
        width: 100%;
        padding: 0;
        text-align: left; }
    .im-chat-modal-body-box .chat-item-box .ant-avatar {
      vertical-align: top; }
    .im-chat-modal-body-box .chat-item-box .chat-item-main {
      display: inline-block;
      max-width: 300px; }
      .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-box {
        font-size: 12px;
        color: var(--font-tips);
        margin-bottom: 10px; }
      .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content {
        display: inline-block;
        vertical-align: top;
        background: var(--color);
        padding: 6px;
        border-radius: 4px;
        color: #fff;
        text-align: left;
        white-space: normal;
        word-break: break-all;
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1); }
        .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .text-box {
          -webkit-user-select: text;
          -ms-user-select: text;
              user-select: text; }
          .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .text-box img {
            width: 28px !important;
            height: 28px !important; }
        .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .image-box img {
          max-width: 100%; }
        .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .custom-box {
          display: flex;
          width: 100%;
          text-align: left; }
          .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .custom-box .custom-img-box {
            width: 120px;
            height: 120px; }
          .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .custom-box .custom-content-box {
            flex: 1 1;
            margin-left: 24px; }
        .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .custom-emoji-box {
          width: 120px;
          height: 120px; }
        .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .file-box {
          display: flex; }
          .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .file-box img {
            height: 40px;
            color: #fff;
            vertical-align: middle; }
          .im-chat-modal-body-box .chat-item-box .chat-item-main .chat-info-content .file-box span {
            flex: 1 1;
            padding-left: 6px;
            text-align: left;
            vertical-align: middle; }
  .im-chat-modal-body-box .history-loader {
    width: 100%;
    text-align: center;
    line-height: 20px; }

.im-chat-modal-footer-box {
  height: 158px;
  border-top: 1px solid var(--border-deep); }
  .im-chat-modal-footer-box .im-chat-tool-box {
    height: 38px;
    padding: 0 8px;
    border-bottom: 1px solid var(--border-deep);
    line-height: 38px; }
    .im-chat-modal-footer-box .im-chat-tool-box .item {
      margin: 0 8px;
      font-size: 18px;
      cursor: pointer;
      position: relative; }
      .im-chat-modal-footer-box .im-chat-tool-box .item .ant-popover-inner-content {
        padding: 0; }
      .im-chat-modal-footer-box .im-chat-tool-box .item:hover .anticon, .im-chat-modal-footer-box .im-chat-tool-box .item:active .anticon {
        opacity: .8; }
      .im-chat-modal-footer-box .im-chat-tool-box .item .file {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        cursor: pointer; }
      .im-chat-modal-footer-box .im-chat-tool-box .item.history {
        float: right; }
  .im-chat-modal-footer-box .im-chat-textarea-box {
    display: block;
    width: 100%;
    padding: 5px 0 0 10px;
    height: 68px;
    line-height: 18px;
    border: none;
    overflow: auto;
    resize: none;
    background: 0 0; }
  .im-chat-modal-footer-box .im-chat-bottom-box {
    height: 46px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    padding: 0 16px; }
    .im-chat-modal-footer-box .im-chat-bottom-box .btn {
      vertical-align: top;
      font-size: 14px;
      line-height: 32px;
      margin-left: 5px;
      padding: 0 20px;
      background-color: var(--color);
      color: #fff;
      border-radius: 3px;
      border: 0 none; }
      .im-chat-modal-footer-box .im-chat-bottom-box .btn:hover, .im-chat-modal-footer-box .im-chat-bottom-box .btn:active {
        opacity: .8; }

.custom-msg-wrap-box {
  background: #fff;
  padding: 12px;
  border-radius: 3px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  cursor: pointer; }
  .custom-msg-wrap-box .img-box {
    width: 60px;
    height: 60px; }
  .custom-msg-wrap-box .info-box {
    flex: 1 1;
    padding-left: 8px; }
    .custom-msg-wrap-box .info-box .title-box {
      font-size: 14px;
      color: var(--font-title);
      margin-bottom: 4px; }
    .custom-msg-wrap-box .info-box .tips-box {
      font-size: 14px;
      color: var(--font-tips);
      margin-bottom: 4px; }
    .custom-msg-wrap-box .info-box .label-box {
      display: inline-block;
      line-height: 20px;
      font-size: 12px;
      color: var(--font-tips); }
      .custom-msg-wrap-box .info-box .label-box .label-tag {
        display: inline-block;
        margin-right: 6px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        color: var(--color);
        border: 1px solid var(--color);
        border-radius: 2px;
        text-align: center;
        line-height: 18px; }
    .custom-msg-wrap-box .info-box .btn-box {
      float: right; }
      .custom-msg-wrap-box .info-box .btn-box .btn {
        width: 80px;
        height: 32px;
        background: var(--color);
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        border: 0 none; }
        .custom-msg-wrap-box .info-box .btn-box .btn:hover, .custom-msg-wrap-box .info-box .btn-box .btn:active {
          opacity: .8; }

.editor-box {
  width: 100%;
  height: 100%; }
  .editor-box .editor-content {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: auto;
    font-size: 14px;
    color: #333; }
    .editor-box .editor-content img {
      width: 24px;
      height: 24px; }

.emoji-box {
  background: #F1F1F1;
  border-top: 1px solid var(--border-deep);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 402px;
  height: 200px; }
  .emoji-box .emoji-tabs-box {
    height: 32px;
    padding: 0 12px;
    line-height: 32px;
    background: #F9F9F9; }
    .emoji-box .emoji-tabs-box .emoji-album-item {
      display: inline-block;
      margin-right: 8px;
      padding: 0 6px; }
      .emoji-box .emoji-tabs-box .emoji-album-item.active {
        background: #F1F1F1; }
      .emoji-box .emoji-tabs-box .emoji-album-item img {
        width: 22px;
        height: 22px; }
  .emoji-box .emoji-body-box {
    flex: 1 1;
    height: 0;
    overflow: auto;
    padding: 12px; }
    .emoji-box .emoji-body-box .emoji-item {
      display: inline-block;
      width: 40px;
      height: 40px;
      padding: 6px;
      border: 1px solid var(--border-deep);
      cursor: pointer; }
      .emoji-box .emoji-body-box .emoji-item.size {
        width: 60px;
        height: 60px; }
      .emoji-box .emoji-body-box .emoji-item img {
        width: 100%;
        height: 100%; }

.emoji-preview {
  max-width: 60px; }

.useful-expressions-box {
  width: 400px; }
  .useful-expressions-box dt, .useful-expressions-box dd {
    padding: 10px 12px;
    border-bottom: 1px solid #e3e3e3;
    color: #333; }
  .useful-expressions-box dd {
    color: #666; }
    .useful-expressions-box dd:hover {
      cursor: pointer;
      color: var(--color); }

.user-menu-box {
  width: 204px;
  padding: 24px;
  margin-top: 14px;
  line-height: 1;
  color: var(--font-title); }
  .user-menu-box dt {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;
    padding-top: 16px; }
    .user-menu-box dt:first-child {
      padding-top: 0; }
  .user-menu-box dd {
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 16px; }
    .user-menu-box dd:hover, .user-menu-box dd.active {
      color: var(--color); }

.page-footer-box {
  width: 100%;
  background: #fff;
  position: relative; }
  .page-footer-box:hover .page-foot-edit-opt {
    display: flex; }
  .page-footer-box .page-foot-layout:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0; }
  .page-footer-box .page-foot-layout .editor-box p {
    display: block; }
  .page-footer-box .page-foot-layout .editor-box a {
    display: inline-block; }
  .page-footer-box .shop-help-list-box {
    display: inline-flex;
    font-size: 12px; }
    .page-footer-box .shop-help-list-box .shop-help-list {
      flex: 1 1;
      display: inline-block;
      vertical-align: top;
      text-align: left; }
      .page-footer-box .shop-help-list-box .shop-help-list .title {
        font-size: 16px;
        color: var(--font-title);
        font-weight: bold;
        margin-bottom: 8px;
        line-height: 1; }
      .page-footer-box .shop-help-list-box .shop-help-list .txt {
        font-size: 12px;
        color: var(--font-normal);
        line-height: 24px;
        cursor: pointer; }
        .page-footer-box .shop-help-list-box .shop-help-list .txt:hover {
          color: var(--color); }
  .page-footer-box .wrap {
    position: relative;
    z-index: 2; }
  .page-footer-box .page-foot-background {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .page-footer-box .page-foot-background img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: none; }

.page-head-wrap-fix {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0; }

.opacity-0 {
  z-index: -1;
  opacity: 0; }

.opacity-1 {
  z-index: 2;
  opacity: 1; }

.header-banner-box {
  width: 100%;
  overflow: hidden;
  position: relative; }
  .header-banner-box img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 100%; }

.shop-head-user-box {
  height: 32px;
  background: #F8F8F8;
  border-bottom: 1px solid #eee; }
  .shop-head-user-box .main-box {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between; }
  .shop-head-user-box .head-welcome-txt {
    font-size: 12px;
    color: var(--font-normal);
    padding-left: 24px;
    line-height: 32px; }
  .shop-head-user-box .head-user-nav-box {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end; }
    .shop-head-user-box .head-user-nav-box .ant-popover-inner-content {
      padding: 0 !important; }
    .shop-head-user-box .head-user-nav-box p {
      margin-bottom: 0; }
    .shop-head-user-box .head-user-nav-box .nav-card {
      margin-left: 16px;
      cursor: pointer;
      font-size: 12px; }

.nav-card-main {
  display: flex;
  padding: 16px; }
  .nav-card-main .qr-code-box {
    text-align: center;
    margin: 0 12px; }
    .nav-card-main .qr-code-box p {
      margin-bottom: 0; }
    .nav-card-main .qr-code-box .code-box {
      display: inline-block;
      width: 104px;
      height: 104px; }
      .nav-card-main .qr-code-box .code-box img {
        width: 100%;
        height: 100%; }

.user-nav-card-box .user-nav-card {
  width: 100px;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid var(--border-deep);
  cursor: pointer;
  color: var(--font-normal);
  padding: 0 12px; }
  .user-nav-card-box .user-nav-card:hover {
    color: var(--color); }

.head-main-box {
  position: relative; }
  .head-main-box .line-wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: var(--color); }
  .head-main-box .main-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 78px; }
    .head-main-box .main-wrap .logo-box {
      width: 200px;
      height: 50px; }
      .head-main-box .main-wrap .logo-box img {
        width: 100%;
        height: 100%; }
    .head-main-box .main-wrap .search-wrap {
      position: relative; }
      .head-main-box .main-wrap .search-wrap .cart-box {
        display: inline-flex;
        vertical-align: middle;
        margin-left: 12px;
        height: 36px;
        align-items: center;
        align-content: center;
        justify-content: center;
        border: 1px solid var(--border-deep);
        padding: 0 12px;
        cursor: pointer; }
        .head-main-box .main-wrap .search-wrap .cart-box i {
          margin-right: 6px; }
    .head-main-box .main-wrap .search-box {
      width: 400px;
      height: 36px;
      line-height: 36px;
      vertical-align: middle;
      display: inline-flex;
      align-content: center;
      align-items: center;
      border: 2px solid var(--color);
      position: relative; }
      .head-main-box .main-wrap .search-box .txt {
        flex: 1 1;
        border: 0 none;
        font-size: 14px; }
      .head-main-box .main-wrap .search-box .ant-input-suffix {
        padding: 0 4px;
        text-align: center; }
      .head-main-box .main-wrap .search-box .ant-input-affix-wrapper-focused {
        box-shadow: 0 0 2px var(--color); }
      .head-main-box .main-wrap .search-box .search-btn {
        min-width: 54px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        background: var(--color);
        color: #fff;
        cursor: pointer;
        border: 0 none; }
        .head-main-box .main-wrap .search-box .search-btn:disabled {
          opacity: .8; }
        .head-main-box .main-wrap .search-box .search-btn:hover {
          opacity: .8; }
      .head-main-box .main-wrap .search-box .dropdown-box {
        position: absolute;
        top: 34px;
        left: -2px;
        right: -2px;
        background: #fff;
        z-index: 1; }
    .head-main-box .main-wrap .search-dropdown-box {
      position: absolute;
      top: 36px;
      left: 0;
      right: 0;
      height: 344px;
      background: #fff;
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #f0f0f0;
      z-index: 1; }
      .head-main-box .main-wrap .search-dropdown-box .search-dropdown-wrap {
        padding: 16px; }
      .head-main-box .main-wrap .search-dropdown-box .log-item {
        font-size: 12px;
        cursor: pointer;
        padding: 12px 0;
        border-bottom: 1px solid var(--border-deep); }
        .head-main-box .main-wrap .search-dropdown-box .log-item:first-child {
          padding-top: 0; }
        .head-main-box .main-wrap .search-dropdown-box .log-item .tit {
          color: var(--font-title); }
        .head-main-box .main-wrap .search-dropdown-box .log-item .txt {
          color: var(--font-tips); }
        .head-main-box .main-wrap .search-dropdown-box .log-item .del:hover {
          color: var(--color); }

.search-log-list-box {
  border: 1px solid var(--color);
  background: #fff;
  max-height: 300px;
  display: flex;
  flex-direction: column; }
  .search-log-list-box .keyword-foot-box {
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding: 0 12px;
    text-align: right;
    border-top: 1px solid var(--border-deep); }
    .search-log-list-box .keyword-foot-box .txt {
      font-size: 14px;
      color: var(--font-normal);
      cursor: pointer; }
      .search-log-list-box .keyword-foot-box .txt:hover {
        color: var(--color); }
  .search-log-list-box .logo-box {
    flex: 1 1;
    height: 0;
    overflow: scroll;
    width: 100%; }
  .search-log-list-box .log-item {
    height: 24px;
    line-height: 24px;
    padding: 0 12px;
    display: flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: var(--color);
    font-size: 12px;
    margin: 0 !important; }
    .search-log-list-box .log-item .tips {
      color: var(--font-tips); }
    .search-log-list-box .log-item .ope {
      position: relative;
      z-index: 2; }
    .search-log-list-box .log-item .del {
      display: none; }
    .search-log-list-box .log-item:hover {
      background: #fff; }
      .search-log-list-box .log-item:hover .tips {
        display: none; }
      .search-log-list-box .log-item:hover .del {
        display: block; }
      .search-log-list-box .log-item:hover:after {
        content: "";
        background: var(--color);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: .1;
        z-index: 0; }
    .search-log-list-box .log-item .txt {
      flex: 1 1;
      width: 0;
      position: relative;
      z-index: 2; }

.nav-wrap {
  display: flex;
  align-content: center;
  align-items: center; }
  .nav-wrap .menu-wrap {
    position: relative;
    width: 204px; }
    .nav-wrap .menu-wrap:hover .menu-box {
      display: block; }
    .nav-wrap .menu-wrap .menu-title {
      width: 100%;
      height: 40px;
      background-color: var(--color);
      color: #FFFFFF;
      padding: 0 24px;
      line-height: 38px;
      font-size: 16px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between; }
      .nav-wrap .menu-wrap .menu-title i {
        font-size: 18px; }
    .nav-wrap .menu-wrap .menu-box {
      display: none;
      position: absolute;
      width: 100%;
      z-index: 2; }
      .nav-wrap .menu-wrap .menu-box .menu-main-box {
        width: 100%;
        background: #fff; }
        .nav-wrap .menu-wrap .menu-box .menu-main-box .menu-item {
          display: inline-flex;
          align-content: center;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 38px;
          line-height: 38px;
          font-size: 14px;
          color: var(--font-title);
          padding: 0 12px;
          border-bottom: 1px solid var(--border-deep);
          cursor: pointer; }
          .nav-wrap .menu-wrap .menu-box .menu-main-box .menu-item .icon-jump {
            font-size: 12px;
            color: #ccc;
            pointer-events: none; }
          .nav-wrap .menu-wrap .menu-box .menu-main-box .menu-item:hover {
            background: var(--color-hover);
            color: var(--color); }
            .nav-wrap .menu-wrap .menu-box .menu-main-box .menu-item:hover .goto {
              color: var(--color); }
  .nav-wrap .nav-box {
    flex: 1 1;
    width: 0;
    overflow: hidden;
    white-space: nowrap; }
    .nav-wrap .nav-box .nav-item {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 600;
      height: 38px;
      line-height: 34px;
      margin: 0 28px 2px;
      cursor: pointer;
      color: #333; }
      .nav-wrap .nav-box .nav-item:hover, .nav-wrap .nav-box .nav-item.active {
        color: var(--color); }

.menu-sub-box {
  display: none;
  position: absolute;
  top: 0;
  left: 204px;
  width: 996px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  line-height: 1;
  font-size: 0;
  cursor: pointer;
  z-index: 2;
  overflow: auto;
  padding: 0 24px; }
  .menu-sub-box dl {
    width: calc(50% - 6px);
    display: inline-block;
    vertical-align: top; }
    .menu-sub-box dl:nth-child(2n -1) {
      margin-right: 12px; }
  .menu-sub-box dt {
    padding: 24px 0 12px;
    color: var(--font-title);
    border-bottom: 1px solid var(--border-middle);
    font-size: 14px;
    font-weight: bold; }
    .menu-sub-box dt:hover {
      color: var(--color);
      background-color: var(--color-hover); }
  .menu-sub-box dd {
    display: inline-block;
    padding: 0 10px;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    color: var(--font-normal);
    margin-right: 8px;
    position: relative; }
    .menu-sub-box dd .short-line {
      display: inline-block;
      width: 1px;
      background: var(--border-deep);
      position: absolute;
      height: 16px;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .menu-sub-box dd:hover {
      color: var(--color);
      background-color: var(--color-hover); }
  .menu-sub-box .nav-service-item-box {
    padding: 16px 0;
    font-size: 0; }
  .menu-sub-box .item-box-card {
    display: inline-block;
    vertical-align: top;
    width: 144px;
    line-height: 20px;
    margin-right: 16px;
    margin-bottom: 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: var(--font-title);
    text-align: center; }
    .menu-sub-box .item-box-card:hover {
      color: var(--color); }
    .menu-sub-box .item-box-card:nth-child(6n) {
      margin-right: 0; }
  .menu-sub-box .active {
    color: var(--color-active); }

.menu-active {
  display: block; }

.menu-brand-item-box {
  padding: 52px 0 16px;
  font-size: 0;
  position: relative;
  height: 100%; }
  .menu-brand-item-box p {
    margin-bottom: 0; }
  .menu-brand-item-box .first-letter-box {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    font-size: 14px;
    color: var(--font-title);
    margin-bottom: 12px;
    z-index: 2; }
    .menu-brand-item-box .first-letter-box .letter {
      display: inline-block;
      vertical-align: middle;
      padding: 0 8px;
      height: 24px;
      line-height: 24px;
      border: 1px solid transparent;
      cursor: pointer; }
      .menu-brand-item-box .first-letter-box .letter:hover {
        color: var(--color); }
      .menu-brand-item-box .first-letter-box .letter.active {
        border-color: var(--color);
        color: var(--color); }
  .menu-brand-item-box .brand-item-box-main {
    height: 100%;
    overflow: auto; }
  .menu-brand-item-box .brand-box-card {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 120px;
    height: 70px;
    border: 1px solid var(--border-deep);
    cursor: pointer;
    transition: all 0.4s ease-out;
    word-break: break-all; }
    .menu-brand-item-box .brand-box-card:hover .info-box {
      opacity: 1;
      transition: opacity .3s linear; }
    .menu-brand-item-box .brand-box-card .img-box {
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      overflow: hidden; }
      .menu-brand-item-box .brand-box-card .img-box span {
        font-size: 14px;
        color: var(--font-title);
        padding: 0 16px;
        font-weight: bold; }
      .menu-brand-item-box .brand-box-card .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .menu-brand-item-box .brand-box-card .info-box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      opacity: 0;
      transition: opacity .3s linear; }
      .menu-brand-item-box .brand-box-card .info-box .title-box {
        padding: 0 8px;
        text-align: center; }
      .menu-brand-item-box .brand-box-card .info-box .title {
        font-size: 14px;
        line-height: 20px; }
      .menu-brand-item-box .brand-box-card .info-box .tips {
        font-size: 12px;
        line-height: 18px; }

.affix-box {
  position: fixed;
  right: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1; }
  .affix-box .affix-item {
    width: 36px;
    height: 36px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 16px; }
    .affix-box .affix-item:last-child {
      margin-bottom: 0; }
    .affix-box .affix-item:hover .iconfont {
      color: var(--color); }
    .affix-box .affix-item .iconfont {
      font-size: 24px;
      line-height: 36px;
      color: #9B9B9B; }
  .affix-box .qrcode-box {
    text-align: center; }
    .affix-box .qrcode-box .img-box {
      width: 136px;
      margin-top: 12px; }
      .affix-box .qrcode-box .img-box img {
        height: 136px; }
      .affix-box .qrcode-box .img-box .txt {
        font-size: 12px;
        color: var(--font-tips);
        margin-top: 8px; }
  .affix-box .service-box {
    text-align: left; }
    .affix-box .service-box dt {
      font-size: 12px;
      color: var(--font-tips);
      margin-bottom: 10px; }
    .affix-box .service-box dd {
      margin-bottom: 8px; }
      .affix-box .service-box dd:hover {
        color: var(--color); }
        .affix-box .service-box dd:hover .txt {
          color: var(--color); }
      .affix-box .service-box dd .svg-icon {
        width: 16px;
        height: 16px; }
      .affix-box .service-box dd .tel {
        font-size: 16px;
        font-weight: bold; }

.svg-icon {
  width: 44px;
  height: 44px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden; }

