.order-pay-head {
  padding: 0 24px;
  margin-top: 12px;
  background: #fff;

  .headline {
    display: flex;
    justify-content: space-between;
    line-height: 78px;
    border-bottom: 1px solid var(--border-deep);

    .icon-fail1,
    .icon-succeed {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      vertical-align: middle;
    }


  }

  .row {
    padding: 12px 0;
    line-height: 38px;
    font-size: 14px;
    color: var(--font-normal);

    .item {
      cursor: pointer;

      &:hover {
        color: var(--color);
      }
    }
  }
}

.order-pay-page-main {
  background: #fff;
  padding: 0 24px;
  margin-top: 14px;
}

.pay-money-box {
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border-deep);
  background: #fff;

  .left-box {
    .tit {
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      color: var(--font-title);
      margin-right: 16px;
    }

    .txt {
      display: inline-block;
      vertical-align: middle;
      width: 240px;
      height: 42px;
      border: 1px solid var(--border-deep);
      border-radius: 2px;
      overflow: hidden;
      position: relative;

      &:before {
        content: "￥";
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        color: var(--font-high);
        line-height: 1;
      }

      input {
        width: 100%;
        height: 100%;
        padding-left: 30px;
        border: 0 none;
        font-size: 24px;
        color: var(--font-high);
      }
    }
  }

  .right-box {
    flex: 1;
    text-align: right;

    p {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      font-size: 14px;
      color: var(--font-title);
      line-height: 1;

      span {
        padding-left: 6px;
        color: var(--font-normal);
      }
    }

    .time {
      color: var(--font-high);

      .iconfont {
        margin-right: 6px;
        font-size: 16px;
      }
    }
  }
}

.balance-pay-select {
  padding: 24px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border-deep);

  .left-box {
    flex: 1;
    font-size: 14px;
    color: var(--font-title);

    i {
      margin-right: 8px;
    }
  }

  .right-box {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: var(--font-normal);
  }
}

.pay-type-box {
  padding-top: 24px;
  min-height: 400px;

  li {
    margin-bottom: 30px;
    min-width: 200px;
    cursor: pointer;

    .iconfont {
      display: inline-block;
      vertical-align: middle;
      color: var(--font-normal);
    }


    .svg-icon {
      margin: 0 14px;
      width: 32px;
      height: 32px;
      vertical-align: middle;
    }

    .txt {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      color: #222;

      .balance {
        font-size: 18px;
        color: var(--font-normal);
      }
    }

    .bank-txt {
      display: inline-block;
      padding-left: 4px;
      vertical-align: middle;
      font-size: 12px;
      color: var(--color);
      cursor: pointer;

      i {
        display: inline-block;
        margin-right: 4px;
      }
    }
  }

  .btn-box {
    .btn {
      display: inline-block;
      vertical-align: middle;
      width: 180px;
      height: 48px;
      background: var(--color);
      border-radius: 4px;
      border: 0 none;
      margin-right: 20px;
      font-size: 16px;
      line-height: 48px;
      text-align: center;
      color: #fff;
      cursor: pointer;

      &:nth-child(2) {
        background: #fff;
        color: var(--color);
        border: 1px solid var(--color);
      }
    }
  }

  .order-pay-remark {
    padding: 0;
    border: 0 none;
    margin: 0;

    .txt-red {
      margin-bottom: 4px;
    }

    .image-upload {
      margin-top: 12px;
    }
  }
}

.page-order-pay-result {
  margin: 10px auto;
  padding: 22px 16px;
}

.order-pay-result-head {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 32px 0;
  font-weight: 700;
  background: #EFFAEC;

  .svg-icon {
    width: 56px;
    height: 56px;
    margin-right: 16px;
  }

}

.order-pay-result-detail {
  table {
    margin: 16px auto;

    td {
      padding: 2px;
    }
  }
}

.order-pay-result-operate {
  text-align: center;

  .btn {
    width: 116px;
    height: 32px;
    margin: 4px;
    font-size: 14px;
    color: var(--font-normal);
    border: 1px solid var(--border-deep);
    background: transparent;
  }
}

.order-list-page {
  margin: 16px 0;

  .ant-pagination {
    padding: 32px 0 8px;
    text-align: center;
  }

  .ant-spin {
    display: block;
    padding: 48px 0 36px;
  }

  .ant-empty {
    padding: 48px 0 36px;
  }

  .order-list-content {
    padding: 16px;
    min-height: 400px;
  }

  .order-list-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 8px;

    .tabs-box {
      .tab {
        display: inline-block;
        vertical-align: middle;
        margin: 0 14px;
        font-size: 14px;
        color: var(--font-normal);
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &:hover {
          color: var(--color);
        }

        &.active {
          color: var(--color);
          border-color: var(--color);
        }
      }
    }

    .search-box {
      .input-wrap, .select-box {
        display: inline-block;
        vertical-align: middle;
      }

      .input-wrap {
        margin-right: 8px;
      }
    }
  }

  .order-list-body {
    table {
      width: 100%;
      font-size: 14px;
      color: var(--font-normal);
      table-layout: fixed;

      thead {
        tr {
          border: 1px solid var(--border-deep);
        }

        th {
          background: #f5f5f5;
          line-height: 40px;
          padding: 0 16px;
          text-align: center;
          width: 128px;

          &:nth-child(1) {
            text-align: left;
            width: 416px;
          }

          &:nth-child {
            width: 162px;
          }
        }
      }

      tbody {
        &:before {
          content: "";
          display: block;
          height: 16px;
        }

        tr {
          &:first-child {
            background-color: #f5f5f5;
            line-height: 40px;
            padding: 0 16px;
          }
        }

        td {
          padding: 16px;
          text-align: center;
          border: 1px solid var(--border-deep);

          &:first-child {
            text-align: left;
            padding: 0;
          }

          .ant-btn {
            margin-bottom: 8px;
          }
        }
      }

      .col {
        display: inline-block;
        padding: 0 16px;
        margin-right: 32px;
        color: var(--font-tips);
      }

      .code {
        color: var(--font-normal);
      }

      .status {
        color: var(--font-tips);
      }

      .link {
        display: block;
        color: var(--font-title);

        &:hover {
          color: var(--color);
        }
      }

      .print-btn {
        background-color: transparent;
        border: 0 none;
        color: var(--font-title);
      }

      .text {
        display: inline-block;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
      }

      .img-box {
        display: inline-block;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        position: relative;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &:after {
          content: attr(data-number);
          display: inline-block;
          width: 100%;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 12px;
          color: #fff;
          background: rgba(0, 0, 0, .8);
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      .item-list {
        vertical-align: top;

        .item {
          display: flex;
          justify-content: space-between;
          padding: 22px 16px;
          border-bottom: 1px solid var(--border-deep);

          &:last-child {
            border-bottom: 0;
          }

          .name {
            flex: 1;
            padding-right: 16px;
          }
        }
      }
    }
  }
}

.order-detail-page {
  padding: 16px;
  background: #fff;

  .ant-spin {
    width: 100%;
    text-align: center;
    margin: 48px 0;
  }
}

.order-detail-chunk {
  display: flex;
  padding: 16px 0;
  margin-bottom: 16px;
  border: 1px solid var(--border-deep);

  .chunk-column {
    flex: 1;
    padding: 0 16px;
    border-left: 1px solid var(--border-deep);

    &:first-child {
      border: 0 none;
    }
  }

  .order-detail-code {
    flex: 0 0 260px;

    .status {
      font-size: 24px;
      font-weight: 600;
      margin-top: 16px;
      margin-bottom: 8px;
      text-align: center;
      color: var(--font-title);
    }
  }

  .order-detail-steps {
    display: flex;
    justify-content: center;

    .step {
      text-align: center;
      display: flex;

      &:last-child {
        .arrow {
          display: none;
        }
      }
    }

    .step-active {
      .iconfont {
        color: var(--color);
      }
    }

    .detail {
      width: 4em;
    }

    .iconfont {
      font-size: 24px;
      color: var(--font-tips);
    }

    .icon-progress-bar {
      font-size: 17px;
    }

    .label {
      display: block;
      color: var(--font-title);
    }

    .tips {
      display: inline-block;
      width: 70px;
      text-align: left;
      font-size: 12px;
      line-height: 1.3;
      color: var(--font-tips);
    }

    .arrow {
      padding-top: 4px;
    }
  }

  .order-log-btn {
    font-size: 14px;
    padding-top: 10px;
    padding-left: 42px;
    color: var(--font-normal);
    cursor: pointer;
  }

  dl {
    dt {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 600;

      .btn-logistics {
        width: 80px;
        height: 24px;
        border: 1px solid var(--color);
        color: var(--color);
        background: transparent;
        border-radius: 2px;

        &:hover {
          color: var(--color-active);
          border-color: var(--color-active);
        }
      }

      .btn-pay {
        width: 80px;
        height: 24px;
        border: 1px solid var(--color);
        color: var(--color);
        background: transparent;
        border-radius: 2px;

        &:hover {
          color: var(--color-active);
          border-color: var(--color-active);
        }
      }
    }

    dd {
      display: flex;
      margin-bottom: 8px;
      font-size: 14px;

      .label {
        width: 70px;
        color: var(--font-normal);
      }

      .value {
        flex: 1;
        width: 0;
        padding-left: 16px;
        color: var(--font-title);
      }

      .img-box {
        display: inline-block;
        vertical-align: top;
        height: 70px;
        width: 70px;
        text-align: center;
        line-height: 70px;
        border: 1px solid #f5f5f5;
        margin-right: 4px;
        margin-bottom: 4px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .tips {
        cursor: pointer;
      }
    }
  }
}

.order-detail-table {

  table {
    width: 100%;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    color: var(--font-normal);

    th {
      padding: 0 16px;
      line-height: 40px;
      background: #f5f5f5;

      &:nth-child(1) {
        width: 260px;
        color: var(--font-title);
        font-weight: 600;
      }

      &:nth-child(2) {
        width: 156px;
      }

      &:nth-child(3) {
        width: 156px;
      }

      &:nth-child(4) {
        width: 90px;
      }

      &:nth-child(5) {
        width: 90px;
      }

      &:nth-child(6) {
        width: 120px;
      }

      &:nth-child(7) {
        width: 120px;
      }

      &:nth-child(8) {
        text-align: center;
      }
    }

    td {
      padding: 16px;
      line-height: 22px;
      border-bottom: 1px solid var(--border-deep);

      &:nth-child(1) {
        color: var(--font-title);
      }

      &[rowSpan] {
        border-left: 1px solid var(--border-deep);
        border-right: 1px solid var(--border-deep);
        text-align: center;
      }

    }

    .opt-btn {
      background: transparent;
      border: 0 none;

      &.btn-disabled {
        color: var(--disabled);
      }
    }
  }
}

.order-total-table {
  border: 1px soild var(--border-deep);

  table {
    margin-left: auto;

    td {
      padding: 10px 12px 0;

      &:nth-child(1) {
        text-align: right;
      }
    }

    .total {
      font-size: 16px;
      font-weight: 600;
      color: var(--font-high);
    }
  }
}

.back-edit-box {
  min-height: 400px;

  .goods-info-box {
    margin-bottom: 16px;

    table {
      width: 100%;
      table-layout: fixed;
      font-size: 12px;
      color: var(--font-title);
      font-weight: normal;
      text-align: left;
    }

    th {
      height: 32px;
      vertical-align: 32px;
      padding-right: 16px;
    }

    td {
      padding-right: 16px;

      .txt {
        display: inline-block;
        width: 100%;
      }
    }
  }

  .remark-box {
    .text-area {
      position: relative;

      .ant-input-textarea-clear-icon {
        margin-right: 28px;
      }

      textarea {
        padding-bottom: 32px;
      }

      &:after {
        content: attr(data-number);
        font-size: 14px;
        color: var(--font-tips);
        position: absolute;
        right: 28px;
        bottom: 6px;
      }
    }

    .img-upload-box {
      margin-top: 16px;

      .title {
        margin-bottom: 8px;
        font-size: 14px;
        color: var(--font-tips);
      }
    }
  }
}

.back-result-box {
  margin-top: 40px;
  padding-top: 16px;
  border-top: 1px solid var(--border-deep);

  li {
    font-size: 14px;
    color: var(--font-title);
    margin-bottom: 4px;

    span:first-child {
      color: var(--font-normal);
    }
  }

}

.order-detail-out {
  margin-bottom: 16px;
  border: 1px solid var(--border-deep);

  .headline {
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: var(--font-normal);
    background: #f5f5f5;
  }

  .out-list-item {
    display: flex;
    padding: 8px 16px;
    border-bottom: 1px solid var(--border-deep);

    &:last-child {
      border-bottom: 0 none;
    }

    .img-box {
      position: relative;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 48px;
      overflow: hidden;

      .tips {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        color: #fff;
        font-size: 12px;
        line-height: 22px;
      }
    }

    .detail-box {
      padding: 0 16px;
      flex: 1;
      width: 0;

      .name {
        color: var(--font-title);
      }

      .tips {
        color: var(--font-tips);
      }
    }

    .operate-box {
      align-self: center;
    }
  }

  .btn-logistics {
    width: 80px;
    height: 24px;
    border: 1px solid var(--border-deep);
    margin-right: 8px;
    color: var(--font-title);
    background: transparent;
    border-radius: 2px;

    &:hover {
      color: var(--color-active);
      border-color: var(--color-active);
    }
  }

  .btn-receive {
    width: 80px;
    height: 24px;
    border: 1px solid var(--color);
    color: var(--color);
    background: transparent;
    border-radius: 2px;

    &:hover {
      color: var(--color-active);
      border-color: var(--color-active);
    }
  }
}

.order-log-page {
  padding: 16px;
  background: #fff;

  table {
    width: 100%;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    color: var(--font-normal);

    th {
      padding: 0 16px;
      line-height: 40px;
      background: #f5f5f5;

      &:nth-child(1) {
        width: 250px;
        font-weight: 600;
      }

      &:nth-child(2) {
        width: 680px;
      }
    }

    td {
      padding: 0 16px;
      line-height: 40px;
      border-bottom: 1px solid var(--border-deep);
    }

  }
}

.pay-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 600px;
  transform: translate(-50%, -50%);

  .pay-modal-title {
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    color: var(--font-title);
    background: #fcfcfc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--border-deep);

    .close {
      float: right;
      cursor: pointer;
      margin-right: 24px;

      .iconfont {
        font-size: 15px;
      }
    }
  }

  .pay-modal-body {
    background: #fff;
    border-radius: 0 0 4px 4px;

    .pay-modal-body-head {
      padding: 12px;
      border-bottom: 1px solid var(--border-deep);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      width: 100%;
    }

    .pau-modal-body-main {
      padding: 48px 0;
      text-align: center;

      .main-box {
        display: inline-block;

        .price-box {
          font-size: 18px;
          font-weight: bold;
          color: var(--orangey-red);
        }

        .qrcode-box {
          padding: 2px;
          border: 1px solid var(--border-deep);
          line-height: 1;
        }

        .tips-box {
          margin-top: 12px;
          line-height: 1.3;
          font-size: 12px;
          color: var(--font-normal);
        }
      }
    }
  }
}
