.cart-page-wrap {
  width: 1200px;
  min-width: 1200px;
  min-height: 400px;
  padding: 12px 14px 64px;
  margin: 0 auto;
  background: #fff;

  .ant-spin {
    display: block;
    padding: 48px 0 36px;
  }

  .ant-empty {
    padding: 48px 0 36px;
  }
}

.cart-table {
  .cart-table-head {
    display: flex;
    align-items: center;
    height: 50px;
    border: 1px solid var(--border-deep);
    background: #F5F5F5;

    .ant-checkbox-wrapper {
      padding: 0 10px;
    }

    .item {
      flex-grow: 1; // 是否允许放大
      flex-shrink: 1; // 是否允许缩小
      flex-basis: auto; //
      padding: 10px;
    }
  }

  .cart-table-body {

  }

  .cart-table-body-head {
    line-height: 32px;
    margin-top: 8px;
    font-size: 14px;
    border-bottom: 2px solid #666;
    color: var(--font-title);

    .col {
      margin: 0 12px;

      .time,
      .icon-goods-tag-delivery,
      .icon-goods-tag-shipments {
        color: var(--font-high);
      }
    }

    .ant-checkbox-wrapper {
      padding: 10px;
      margin-right: 10px;
    }
  }


  .cart-table-body-tr {
    border: 1px solid #E3E3E3;
    padding: 4px 0 6px;
    margin-top: -1px;

    .tr-expand {
      padding-left: 10px;

      .iconfont {
        color: var(--color);
        margin-right: 8px;
      }

      .col {
        margin-right: 16px;
        color: var(--font-tips);
      }

      .remark-input {
        width: 390px;
      }
    }

    .tr-expand-check {
      padding-left: 46px;
    }

    .tr-body {
      display: flex;

      .ant-checkbox-wrapper {
        padding: 10px;
      }

      .check-hint {
        margin: 10px 0 0 6px;
        font-size: 12px;
        line-height: 16px;
        height: 16px;
        width: 30px;
        text-align: center;
        border-radius: 8px;
        color: #fff;
        background-color: #999;
      }

      .item {
        flex: 1 1 auto;
        padding: 10px;
        word-break: break-word;
      }

      .price {
        color: var(--font-high);
      }

      .text-btn {
        border: 0 none;
        cursor: pointer;
        color: var(--font-title);
        background: transparent;
      }
    }
  }
}

.page-cart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  height: 48px;
  padding: 0 0 0 24px;
  border-top: 1px solid var(--border-deep);
  border-bottom: 1px solid var(--border-deep);
  background: #FCFCFC;

  .opt-btn {
    font-size: 14px;
    color: var(--font-normal);
    margin: 0 16px 0 24px;
    background: transparent;
    border: 0 none;
    cursor: pointer;

    &:hover {
      color: var(--font-title);
    }
  }

  .btn {
    width: 114px;
    height: 48px;
    font-size: 16px;
    color: #fff;
    margin-left: 24px;
    border: 0 none;
    background: var(--color);

    &:hover,
    &:focus {
      cursor: pointer;
      background: var(--color-active);
    }

    &:disabled {
      background: var(--disabled);
      cursor: not-allowed;
    }
  }

  .text {
    margin-right: 24px;
    font-size: 14px;
    color: var(--font-normal);

    .hint {
      font-size: 18px;
      font-weight: 700;
      color: var(--font-high);
    }
  }

}

.page-crumb {
  line-height: 58px;
  padding: 0 24px;
  font-size: 14px;
  border-bottom: 1px solid var(--border-deep);
  color: var(--font-title);

  .iconfont {
    font-size: 12px;
  }
}

.order-create-wrap {
  padding: 0 14px;
}

.create-order-headline {
  height: 50px;
  padding-top: 18px;
  line-height: 32px;
  margin-bottom: 16px;
  font-size: 14px;
  color: var(--font-title);
  border-bottom: 1px solid var(--border-deep);

  &:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 14px;
    margin-right: 6px;
    background: var(--font-title);
  }

  .txt-btn {
    float: right;
    height: 32px;
    font-size: 14px;
    border: 0 none;
    background: transparent;
    color: var(--color);
  }
}

.create-order-expand {
  display: flex;
  justify-content: space-between;
  padding: 16px 0 0;

  .create-order-radio {
    .row {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .tax-row {
      margin-left: 70px;
      font-size: 14px;

      .btn {
        margin-left: 1em;
        color: #1A81E8;
        cursor: pointer;
      }
    }

    .label {
      min-width: 5em;
      display: inline-block;
      padding-right: 14px;
    }

    .tips {
      color: var(--font-tips);
    }

    .select-tag {
      cursor: pointer;

      .tag {
        font-size: 12px;
        line-height: 16px;
        padding: 0 6px;
        border-radius: 2px;
        color: var(--font-high);
        border: 1px solid var(--color-hint);
      }

      .icon-jump {
        font-size: 12px;
        margin-left: 4px;
        color: var(--font-high);
      }
    }
  }

  .create-order-total {

    td:first-child {
      text-align: right;
      padding-right: 8px;
    }

    .price {
      font-size: 18px;
      color: var(--font-high);
    }

    .icon-shuoming {
      color: #F5A623;
      margin-right: 2px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.create-order-address {
  display: flex;
  flex: 0;
  flex-wrap: wrap;

  .ant-empty {
    width: 100%;
  }

  .address-card {
    position: relative;
    width: 350px;
    height: 168px;
    padding: 0 16px;
    margin: 0 24px 16px 0;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    border-radius: 4px;
    cursor: pointer;

    .icon-selected {
      position: absolute;
      right: -1px;
      bottom: -1px;
      line-height: 1;
      font-size: 18px;
      text-align: right;
      visibility: hidden;
      color: var(--color);
    }

    &.address-card-selected {
      border: 2px solid var(--color);

      .icon-selected {
        visibility: visible;
      }
    }


    .head {
      line-height: 38px;
      margin-bottom: 12px;
      color: var(--font-title);
      border-bottom: 1px solid var(--border-deep);

      .tips {
        float: right;
      }
    }

    .body {
      color: var(--font-normal);
      line-height: 22px;
    }

    .operate {
      position: absolute;
      right: 16px;
      bottom: 16px;
      font-size: 14px;
      color: var(--font-tips);

      .btn {
        &:hover {
          color: var(--color);
        }
      }
    }
  }

}

.create-order-upload {
  margin-top: 10px;

  .headline {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--font-tips);
  }

  .image-upload-btn {
    width: 80px;
    height: 80px;
    padding-bottom: 0;
  }
}
